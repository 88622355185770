// React.
import React, { useState, useEffect } from "react";

// Material UI
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// Redux
import { useDispatch, useSelector } from "react-redux";
import { getItemTypes, updateItemType, createItemType } from "../../../actions/itemTypeActions/ItemTypeActions";
import {RootStore} from "../../../Store";

// Model Types.
import { ItemTypes } from '../models/ModelTypes';

// Styles.
import useStyles from './TypeSettingsStyles';

// Translations.
import { useTranslation } from 'react-i18next';

const TypeSettings = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [t] = useTranslation('global');

  const initialCategory = [{
    id: 0,
    taxPercentage: 0,
    name: '',
    image_url: '',
    description: '',
    isDeleted: false
  }];

  // Redux state.
  const settingsState = useSelector((state: RootStore) => state.itemType);
  const {loading, itemTypeList, actionStatus} = settingsState;

  useEffect(() => {
    dispatch(getItemTypes());
    // eslint-disable-next-line
  }, [actionStatus])

  // Local state.
  const [types, setTypes] = useState<Array<ItemTypes>>(initialCategory);
  const [showErrors, setShowErrors] = useState<boolean>(false);

  useEffect(() => {
    if (itemTypeList !==  undefined) {
      const newArray = [...itemTypeList.categories];
      setTypes(newArray);
    }
    // eslint-disable-next-line
  }, [itemTypeList])

  const addCategoryRow = () => {
    const newArray = [...types];
    newArray.push({
      id: 0,
      taxPercentage: 0,
      name: '',
      image_url: '',
      description: '',
      isDeleted: false,
      isPublished: false
    });
    setTypes(newArray);
  };

  const handleCategoryChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>, index:number) => {
    const name = String(event.target.name);
    var value = String(event.target.value);

    // Filter incorrect numbers
    if (name === 'taxPercentage') {
      const re = /^[0-9\b]+$/;
      if (!re.test(value)) {
        value = '';
      }
    }
    
    // Update the state.
    const newArray = [...types];
    newArray[index] = {
      ...newArray[index],
      [name]: value
    };
    setTypes(newArray);
  };

  const handleCategoryCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, index:number) => {
    const name = String(event.target.name);
    var value = event.target.checked;
    
    // Update the state.
    const newArray = [...types];
    newArray[index] = {
      ...newArray[index],
      [name]: value
    };
    setTypes(newArray);
  };

  const validateFieldValues = () => {
    var res = true;
    types.forEach((category:ItemTypes) => {
      if (category.name.trim() === '' || String(category.taxPercentage) === '' || category.description.trim() === '') {
        res = false;
      }
    });
    return res;
  };

  const saveCategories = () => {
    if (validateFieldValues()) {
      setShowErrors(false);
      types.forEach((category:ItemTypes) => {
        if (category.id !== 0) {
          dispatch(updateItemType(category));
        } else {
          let data = {
            name: category.name.trim(),
            description: category.description.trim(),
            taxPercentage: Number(category.taxPercentage)
          };
          dispatch(createItemType(data));
        }
      });
    } else {
      setShowErrors(true);
    }
  };

  return (
    <Grid container spacing={1} className={classes.root}>
      <Grid item xs={12} sm={12}>
        <Typography variant="h4" color="primary" className={classes.marginBottom}>
          { t('settings.manage-categories') } {loading !== '' && <CircularProgress/>}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        {types?.map((category:ItemTypes, index:number) =>
          <div className={classes.categoryWrapper} key={index}>
            <TextField 
              name="name"
              label={ t('settings.name') }
              value={category.name}
              onChange={(e) => handleCategoryChange(e, index) }
              className="name"
              error={showErrors && category.name.trim() === ''}
            />
            <TextField 
              name="description"
              label={ t('settings.description') }
              value={category.description}
              onChange={(e) => handleCategoryChange(e, index) }
              className="description"
              error={showErrors && category.description.trim() === ''}
            />
            <TextField 
              name="taxPercentage" 
              label={ t('settings.tax-percentage') }
              value={category.taxPercentage}
              onChange={(e) => handleCategoryChange(e, index) }
              type="number"
              className="tax"
              error={showErrors && String(category.taxPercentage) === ''}
            />
            {category.id !== 0 &&
              <FormControlLabel
              control={
                <Checkbox
                  name="isPublished" 
                  checked={ category.isPublished }
                  onChange={(e) => handleCategoryCheckboxChange(e, index) }
                  color="primary"
                />
              }
              label={ t('settings.is-published') }
            />
            }
          </div>
        )}
      </Grid>
      <Grid item xs={12} sm={12}>
        <Button variant="contained" color="default" component="span" onClick={ addCategoryRow }>
          { t('settings.add-category') }
        </Button>
      </Grid>
      <Grid item xs={12} sm={12} className={classes.actionsWrapper}>
        <Button variant="contained" color="primary" component="span" onClick={ saveCategories }>
          { t('settings.save') }
        </Button>
      </Grid>
    </Grid>
  );
};


export default TypeSettings;
