import {
  ITEM_TYPE_FAIL,
  ITEM_TYPE_LOADING,
  ITEM_TYPE_LIST,
  ITEM_TYPE_PUT,
  ITEM_TYPE_CREATE,
  ITEM_TYPE_UPDATE_PER_LABEL_VALUE,
  ItemTypeDispatchTypes,
  ItemTypeList
} from "../actions/itemTypeActions/ItemTypeActionsTypes";

// Default state interface.
interface DefaultStateI {
  loading: string,
  itemTypeList?: ItemTypeList,
  actionStatus?: any
}

// Default state.
const defaultState: DefaultStateI = {
  loading: '',
};

const ItemTypeReducer = (state: DefaultStateI = defaultState, action: ItemTypeDispatchTypes) : DefaultStateI => {
  switch (action.type) {
    case ITEM_TYPE_FAIL:
      return {
        ...state,
        loading: '',
        actionStatus: {menssage: action.payload, status: 'error'}
      }
    case ITEM_TYPE_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case ITEM_TYPE_LIST:
      return {
        ...state,
        loading: '',
        itemTypeList: action.payload
      }
    case ITEM_TYPE_PUT:
      return {
        ...state,
        loading: '',
        actionStatus: {menssage: action.payload, status: 'success'}
      }
    case ITEM_TYPE_CREATE:
      return {
        ...state,
        loading: '',
        actionStatus: {menssage: action.payload, status: 'success'}
      }
    case ITEM_TYPE_UPDATE_PER_LABEL_VALUE:
      return {
        ...state,
        loading: '',
        actionStatus: {menssage: action.payload, status: 'success'}
      }  
    default:
      return state
  }
};


export default ItemTypeReducer;
