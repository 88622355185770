import { withStyles, makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

export const Accordion = withStyles({
  root: {
    margin: '0',
    boxShadow: 'none',
    '& > *': {
      flexDirection: 'row-reverse',
    },
    '&$expanded': {
      margin: '0',
    }
  },
  expanded: {},
})(MuiAccordion);

export const AccordionSummary = withStyles((theme: Theme) => ({
  root: {
    borderBottom: '2px solid rgba(0, 0, 0, .3)',
    marginBottom: 1,
    padding: '0',
    pointerEvents: 'none',
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
      margin: '0',
      borderBottom: '0',
    },
    '& .MuiAvatar-root.MuiAvatar-rounded': {
        width: '120px',
        maxHeight: '120px',
        '& img': {
          maxHeight: '120px',
        },
        [theme.breakpoints.down('md')]: {
          width: '70px',
          maxHeight: '70px',
          '& img': {
            maxHeight: '70px',
          },
        },
    },
    '& .MuiAccordionSummary-expandIcon': {
      display: 'none'
    },
    '& .MuiButtonBase-root': {
      pointerEvents: 'all'
    }
  },
  content: {
    display: 'flex',
    margin: '12px 15px',
    '&$expanded': {
      margin: '0 15px',
    },
    '& > *': {
      alignItems: 'center',
    },
  },
  expanded: {},
}))(MuiAccordionSummary);

export const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderBottom: '2px solid rgba(0, 0, 0, .3)',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 0),
    },
  },
}))(MuiAccordionDetails);

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    circularProgress: {
      textAlign: 'center',
    },
    userImgSmall: {
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      },
    },
    textDecoration: {
      textDecoration: 'line-through'
    },
    type: {
      padding: '0 5px 2px',
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      borderRadius: '4px',
      marginLeft: 0,
      fontSize: '12px'
    }
  })
);
