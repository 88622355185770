// React.
import React, { useState, useEffect, Fragment } from "react";

// Material UI
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import ListSubheader from '@material-ui/core/ListSubheader';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import EditIcon from '@material-ui/icons/Edit';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

// Redux
import { useDispatch, useSelector } from "react-redux";
import { getNav, updateMenu, registerMenu, registerSubMenu, updateSubMenu, deleteMenu, deleteSubMenu, setUpdateNavAdmin } from "../../../actions/navActions/NavActions";
import { getItemCategory } from "../../../actions/categoryActions/categoryActions";
import { getItemTypes } from "../../../actions/itemTypeActions/ItemTypeActions";
import { getItemLabelsByManyTypes } from "../../../actions/itemActions/ItemActions";
import { RootStore } from "../../../Store";

// Styles.
import useStyles from './NavigationSettingsStyles';

// Translations.
import { useTranslation } from 'react-i18next';

export type NavSubItemType = {
  createdDate: string;
  iconUrl: string;
  id: number;
  sequence: number;
  itemTypeIds: string;
  labelValuesIds: string;
  uiUrl: string;
  value: string;
  menuId?: number;
};

export type NavItemType = {
  createdDate: string;
  iconUrl: string;
  id: number;
  sequence: number;
  itemTypeIds: string;
  labelValuesIds: string;
  submenuTitles: NavSubItemType[]
  uiUrl: string;
  value: string;
  menuId?: number;
};

export type DraggableObjectType = {
  navItems: Array<NavItemType>
};

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const NavigationSettings = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [t] = useTranslation('global');

  // Redux state.
  const navState = useSelector((state: RootStore) => state.nav);
  const { navLoading, navItems, actionStatus, mustUpdateAdminNav } = navState;
  const categoryState = useSelector((state: RootStore) => state.itemCategories);
  const { categories } = categoryState;
  const itemTypeState = useSelector((state: RootStore) => state.itemType);
  const { itemTypeList } = itemTypeState;
  const itemState = useSelector((state: RootStore) => state.item);
  const { itemLabelByTypeList } = itemState;

  useEffect(() => {
    dispatch(getNav());
    dispatch(getItemCategory());
    dispatch(getItemTypes());

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (mustUpdateAdminNav) {
      dispatch(getNav());
    }

    if (actionStatus !== undefined) {
      if (actionStatus.status === 'success') {
        handleCloseModal();
      }
    }

    // eslint-disable-next-line
  }, [mustUpdateAdminNav]);

  // Load the BE data in state when aveilable.
  useEffect(() => {
    if (navItems !== undefined && !navLoading) {
      let newNavData = {
        navItems: [...navItems]
      }
      setNavSettings(newNavData);
      dispatch(setUpdateNavAdmin(false));
    }

    // eslint-disable-next-line
  }, [navItems]);

  const [navSettings, setNavSettings] = useState<DraggableObjectType>();
  const [hasError, setHasError] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState(false);
  const [popupContent, setPopupContent] = useState<NavItemType | NavSubItemType>();
  const [popupContentType, setPopupContentType] = useState<string>();
  const [popupContentCategory, setPopupContentCategory] = useState<string>();
  const [popupContentIsDiscount, setPopupContentIsDiscount] = useState<boolean>(false);
  const [popupContentIsBundle, setPopupContentIsBundle] = useState<boolean>(false);
  const [popupContentNoLink, setPopupContentNoLink] = useState<boolean>(false);
  const [toMoveMenuItem, setToMoveMenuItem] = useState<NavItemType | NavSubItemType | null>(null);
  const [displacedMenuItem, setDisplacedMenuItem] = useState<NavItemType | NavSubItemType | null>(null);
  const [selectedParentId, setSelectedParentId] = useState<number|null>(null);
  const [selectionError, setSelectionError] = useState<boolean>(false);
  const [selectionMenuType, setSelectionMenuType] = useState<string>('');
  const [isNewMainNavItem, setIsNewMainNavItem] = useState<boolean>(false);
  const [mustRefresh, setMustRefresh] = useState<boolean>(false);

  // Load label values.
  useEffect(() => {
    if (popupContent?.itemTypeIds && popupContent?.itemTypeIds !== undefined) {
      setIsNewMainNavItem(true) // edit form
      dispatch(getItemLabelsByManyTypes(popupContent.itemTypeIds.split(',')));
    } else {
      setIsNewMainNavItem(false) // new form
    }
    // eslint-disable-next-line
  }, [popupContent?.itemTypeIds]);

  useEffect(() => {
    if ((popupContent !== undefined && openModal) || mustRefresh) {
      let copyPopupContent = { ...popupContent, uiUrl: generateUiURL() } as NavItemType | NavSubItemType;
      setPopupContent(copyPopupContent);
      setMustRefresh(false);
    }

    // eslint-disable-next-line
  }, [mustRefresh, popupContentType, popupContentCategory, popupContentIsDiscount, popupContentIsBundle, popupContentNoLink, openModal]);

  // Triggers the save position function.
  useEffect(() => {
    const toSave = setTimeout(() => {
      saveMenuOrder();
    }, 800);
    return () => clearTimeout(toSave);

    // eslint-disable-next-line
  }, [displacedMenuItem]);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setSelectionError(false);
  };

  const nextSec = (navSettings !== undefined) ? ((navSettings?.navItems[navSettings?.navItems.length - 1]).sequence + 1) : 0;

  const createNavItem = () => {
    let defaultObj = {
      createdDate: '',
      iconUrl: '',
      id: 0,
      sequence: nextSec,
      itemTypeIds: '',
      labelValuesIds: '',
      submenuTitles: [],
      uiUrl: '',
      value: ''
    }

    handleOpenModal(defaultObj, 'item', 0, null);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const getParamFromURL = (item: NavItemType | NavSubItemType, param: string) => {
    let urlParams = new URLSearchParams(item.uiUrl);
    let value = urlParams.get(param);
    if (value) return value;
    return '';
  };

  const handleOpenModal = (item: NavItemType | NavSubItemType, type: string = 'item', parentId: number = 0, e: React.SyntheticEvent|null) => {
    if (e) e.stopPropagation();

    if (parentId !== 0) {
      item.menuId = parentId;
    }
    setOpenModal(true);
    setPopupContent(item);
    setPopupContentType(type);
    setPopupContentCategory(getParamFromURL(item, 'category'));
    setPopupContentIsDiscount(Boolean(getParamFromURL(item, 'isDiscount')));
    setPopupContentIsBundle(Boolean(getParamFromURL(item, 'onlyBundles')));
    setPopupContentNoLink(Boolean(getParamFromURL(item, 'nolink')));
    setHasError(false);
  }

  const getParentNextSecuence = (id: number) => {
    let parent = navSettings?.navItems.filter(item => item.id === id);
    if (parent && parent?.length! > 0 && parent![0]!.submenuTitles.length > 0) {
      let lastPosition = parent![0]!.submenuTitles.length - 1;
      if (lastPosition < 0) return 0;
      return (parent![0]!.submenuTitles[lastPosition].sequence) + 1;
    }
    return 0;
  };

  const menuItemChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    let name = e.target.name as keyof typeof popupContent;
    let newValue = e.target.value;

    var copyPopupContent = { ...popupContent, [name]: newValue, uiUrl: generateUiURL(String(newValue)) } as NavItemType | NavSubItemType;
    if (name === 'menuId') {
      let nextSecuence = getParentNextSecuence(Number(newValue));
      copyPopupContent = { ...copyPopupContent, sequence: nextSecuence };
    }

    setPopupContent(copyPopupContent);
    setMustRefresh(true);
  };

  const menuItemChangeSelectMultiple = (e: React.ChangeEvent<{ value: unknown }>, name: string) => {
    let newValue = e.target.value as string[];
    let formatedValue = newValue.join(',');

    let copyPopupContent = { ...popupContent, [name]: formatedValue, uiUrl: generateUiURL() } as NavItemType | NavSubItemType;
    setPopupContent(copyPopupContent);
    setMustRefresh(true);
  };

  const getTypeNameById = (id: number) => {
    const result = itemTypeList?.categories.filter((type) => type.id === id);
    if (result?.length! > 0) return result![0]!.name;
    return ''
  };

  const getParentName = (id:number) => {
    let parent = navSettings?.navItems.filter(item => item.id === id);
    if (parent?.length! > 0) return parent![0]!.value;
    return '';
  };

  const generateUiURL = (newName: string = '') => {
    var url = '/items';
    const name = (newName) ? newName.replaceAll(' ', '-') : popupContent?.value.replaceAll(' ', '-');
    if (popupContentType === 'item') {
      if (popupContent?.value) url += `?navAction=${encodeURI(name!)}`;
    } else {
      let parentName = getParentName(popupContent?.menuId!).replaceAll(' ', '-');
      if (popupContent?.menuId) url += `?navAction=${encodeURI(parentName)}`;
      if (popupContent?.value) url += `&subNavAction=${encodeURI(name!)}`;
    }
    if (popupContent?.itemTypeIds) url += `&itemTypeIds=${encodeURI(popupContent?.itemTypeIds)}`;
    if (popupContent?.labelValuesIds) url += `&labelValuesIds=${encodeURI(popupContent?.labelValuesIds)}`;
    if (popupContentNoLink) url += `&nolink=true`;
    if (popupContentIsDiscount) url += `&isDiscount=true`;
    if (popupContentIsBundle) url += `&onlyBundles=true`;
    if (popupContentCategory) url += `&category=${popupContentCategory}`;

    return url;
  };

  const handleMenuItemClick = (menuItem: NavItemType | NavSubItemType, parentItemId: number | null = null, menuType:string) => {
    setSelectionError(false);
    if (!toMoveMenuItem) {
      setToMoveMenuItem(menuItem);
      setSelectionMenuType(menuType);
      if (parentItemId) setSelectedParentId(parentItemId);
    } else {
      if (menuItem.id === toMoveMenuItem.id) {
        setToMoveMenuItem(null);
        setSelectedParentId(null);
        setSelectionMenuType('');
      } else {
        if (parentItemId || selectedParentId) {
          if (selectedParentId === parentItemId && menuItem.id !== parentItemId) {
            setDisplacedMenuItem(menuItem);
          } else {
            setSelectionError(true);
          }
        } else {
          setDisplacedMenuItem(menuItem);
        }
      }
    }
  };

  const setSelectedClass = (menuItem: NavItemType | NavSubItemType, menuType:string) => {
    if (toMoveMenuItem) {
      if (toMoveMenuItem.id === menuItem.id && selectionMenuType === menuType) return 'to-move-selected'; 
    }
    if (displacedMenuItem) {
      if (displacedMenuItem.id === menuItem.id && selectionMenuType === menuType) return 'to-displace-selected';
    }
    return '';
  };

  const saveMenuOrder = () => {
    if (toMoveMenuItem && displacedMenuItem) {
      let newPos = displacedMenuItem.sequence;
      const requestObj = toMoveMenuItem;
      requestObj.sequence = newPos;
      if (selectionMenuType === 'menu') {
        dispatch(updateMenu(requestObj as NavItemType));
      } else {
        dispatch(updateSubMenu(requestObj as NavSubItemType));
      }

      // Reset the state.
      setToMoveMenuItem(null);
      setDisplacedMenuItem(null);
      setSelectedParentId(null);
      setSelectionError(false);
      setSelectionMenuType('');
      setTimeout(() => dispatch(setUpdateNavAdmin(true)), 1000);
    }
  };

  const validateForm = () => {
    var error = false;
    if (!popupContent?.value) error = true;
    if (!popupContent?.uiUrl) error = true;
    if (popupContentType === 'item') {
      if (!popupContent?.iconUrl) error = true;
    } else {
      if (!popupContent?.menuId || popupContent?.menuId === 0) error = true;
    }
    setHasError(error);
    return !error;
  };

  const deleteMenuItem = () => {
    if (popupContent?.id !== 0) {
      (popupContentType === 'item') ?
        dispatch(deleteMenu(Number(popupContent?.id))) :
        dispatch(deleteSubMenu(Number(popupContent?.id)));

      setTimeout(() => dispatch(setUpdateNavAdmin(true)), 1000);
    }
  };

  const saveMenuItem = () => {
    if (validateForm()) {
      if (popupContent?.id === 0) {
        (popupContentType === 'item') ?
          dispatch(registerMenu(popupContent as NavItemType)) :
          dispatch(registerSubMenu(popupContent as NavSubItemType));
      } else {
        (popupContentType === 'item') ?
          dispatch(updateMenu(popupContent as NavItemType)) :
          dispatch(updateSubMenu(popupContent as NavSubItemType));
      }
    }
    setTimeout(() => dispatch(setUpdateNavAdmin(true)), 1000);
  };

  return (
    <Fragment>
      <Snackbar open={selectionError} autoHideDuration={5000} onClose={handleClose} className={classes.notification}>
        <Alert onClose={handleClose} severity={'error'}>
          {t('nav.move-error')}
        </Alert>
      </Snackbar>
      <div>
        <Typography variant="h4" color="primary" className={classes.marginBottom}>
          {t('nav.manage-nav')} {navLoading !== '' && <CircularProgress />}
        </Typography>
        <Typography color="textSecondary" className={classes.marginBottom}>
          {t('nav.manage-nav-intructions')}
        </Typography>
        <Button size="medium" variant="outlined" color="primary" onClick={createNavItem}>
          {t('nav.add-menu')}
        </Button>
        <div className={classes.navItemWrapper}>
          {navSettings?.navItems?.map((item, i) => (
            <div key={i} className={classes.rowWrapper}>
              <div className={`${classes.itemRow} ${setSelectedClass(item, 'menu')}`} onClick={() => handleMenuItemClick(item, null, 'menu')}>
                <div>{item.value}</div>

                {(item.value.toLocaleLowerCase() !== 'preventa') &&
                  <IconButton aria-label="edit" onClick={(e) => handleOpenModal(item, 'item', 0, e)}>
                    <EditIcon />
                  </IconButton>
                }
              </div>
              {item.submenuTitles.map((subItem, j) => (
                <div key={j}className={`${classes.rowWrapper} ${classes.level2}`}>
                  <div className={`${classes.itemRow} ${classes.childItem} ${setSelectedClass(subItem, 'submenu')}`} onClick={() => handleMenuItemClick(subItem, item.id,'submenu')}>
                    <div>{subItem.value}</div>

                    <IconButton aria-label="edit" onClick={(e) => handleOpenModal(subItem, 'subitem', item.id, e)}>
                      <EditIcon />
                    </IconButton>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      <div>
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="Confirm Modal"
        >
          <Paper elevation={3} className={classes.modalPaper}>
            <IconButton className={classes.closeModal} aria-label="close modal" component="span" onClick={() => handleCloseModal()}>
              <CloseIcon />
            </IconButton>

            <Grid container>
              <Grid item xs={12} sm={12}>
                <Typography variant="h5" color="primary">{t('nav.menu-item-info')}</Typography><br />
              </Grid>
              <Grid item xs={12} sm={4} className={classes.verticalAlign}>
                <Typography variant="caption" color="initial">{t('nav.menu-item-type')}</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="menu-item-type-label">{t('nav.menu-item-type')}</InputLabel>
                  <Select
                    labelId="menu-item-type-label"
                    input={<Input />}
                    name="menuType"
                    value={popupContentType}
                    onChange={(e: React.ChangeEvent<{ value: unknown }>) => setPopupContentType(String(e.target.value))}
                    autoFocus
                  >
                    <MenuItem value={'item'}>
                      {t('nav.main-menu-item')}
                    </MenuItem>
                    <MenuItem value={'subitem'}>
                      {t('nav.child-menu-item')}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {(popupContentType === 'subitem') &&
                <Fragment>
                  <Grid item xs={12} sm={4} className={classes.verticalAlign}>
                    <Typography variant="caption" color="initial">{t('nav.parent-menu')}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="parent-menu-label">{t('nav.parent-menu')}</InputLabel>
                      <Select
                        labelId="parent-menu-label"
                        input={<Input />}
                        name="menuId"
                        onChange={(e) => menuItemChange(e)}
                        value={popupContent?.menuId!}
                        error={hasError && (!popupContent?.menuId)}
                      >
                        {navSettings?.navItems?.map((item, i) => (
                          <MenuItem key={i} value={item.id}>
                            {item.value}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Fragment>
              }
              <Grid item xs={12} sm={4} className={classes.verticalAlign}>
                <Typography variant="caption" color="initial">{t('nav.menu-title')}</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <FormControl className={classes.formControl}>
                  <TextField
                    placeholder={t('nav.menu-title')}
                    name="value"
                    color="primary"
                    value={popupContent?.value}
                    onChange={(e) => menuItemChange(e)}
                    disabled={isNewMainNavItem && popupContentType === 'item'}
                    error={hasError && (!popupContent?.value)}
                  />
                </FormControl>
              </Grid>
              {(popupContentType === 'item') &&
                <Fragment>
                  <Grid item xs={12} sm={4} className={classes.verticalAlign}>
                    <Typography variant="caption" color="initial">
                      {t('nav.menu-icon-url')} &nbsp;
                      <a href="/admin/manage-navigation/icons" target="_blank">{t('nav.available-icons')}</a>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <FormControl className={classes.formControl}>
                      <TextField
                        placeholder="https://ejemplourl.com/icon.png"
                        name="iconUrl" color="primary" value={popupContent?.iconUrl}
                        onChange={(e) => menuItemChange(e)}
                        error={hasError && (!popupContent?.iconUrl)}
                      />
                    </FormControl>
                  </Grid>
                </Fragment>
              }
              <Grid item xs={12} sm={12}>
                <Typography variant="h5" color="primary">{t('nav.menu-item-filters')}</Typography><br />
              </Grid>
              <Grid item xs={12} sm={4} className={classes.verticalAlign}>
                <Typography variant="caption" color="initial">{t('nav.menu-category')}</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="category-label">{t('nav.menu-category')}</InputLabel>
                  <Select
                    labelId="category-label"
                    input={<Input />}
                    name="category"
                    value={popupContentCategory}
                    onChange={(e) => setPopupContentCategory(String(e.target.value))}
                  >
                    {categories.map((category, c) =>
                      <MenuItem key={c} value={category.name}>
                        {category.name}
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} className={classes.verticalAlign}>
                <Typography variant="caption" color="initial">{t('nav.menu-type')}</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="menu-type-label">{t('nav.menu-type')}</InputLabel>
                  <Select
                    labelId="menu-type-label"
                    input={<Input />}
                    id="itemTypeIds"
                    name="itemTypeIds"
                    multiple
                    value={(popupContent?.itemTypeIds) ? popupContent?.itemTypeIds.split(',') : []}
                    onChange={(e) => menuItemChangeSelectMultiple(e, 'itemTypeIds')}
                  >
                    {itemTypeList?.categories.map((itemType, i) =>
                      <MenuItem key={i} value={`${itemType.id}`}>
                        {itemType.name}
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} className={classes.verticalAlign}>
                <Typography variant="caption" color="initial">{t('nav.label-values')}</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="label-values-label">{t('nav.label-values')}</InputLabel>
                  <Select
                    labelId="label-values-label"
                    id="labelValuesIds"
                    input={<Input />}
                    name="labelValuesIds"
                    multiple
                    value={(popupContent?.labelValuesIds) ? popupContent?.labelValuesIds.split(',') : []}
                    onChange={(e) => menuItemChangeSelectMultiple(e, 'labelValuesIds')}
                  >
                    {itemLabelByTypeList?.map((labelRow) => [
                      <ListSubheader className={classes.listSeparator}>{(getTypeNameById(Number(labelRow.typeId)))}</ListSubheader>,
                      labelRow.labels?.map((label: any) =>
                        label.labelValues?.map((labelValue: any, v: any) =>
                          <MenuItem key={v} value={`${labelValue.id}`}>
                            {`${label.value} | ${labelValue.value}`}
                          </MenuItem>
                        )
                      )
                    ])}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} className={classes.verticalAlign}>
                <Typography variant="caption" color="initial">{t('nav.more-options')}</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <FormControl className={classes.formControl}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={popupContentNoLink}
                        onChange={(e) => setPopupContentNoLink(e.target.checked)}
                      />}
                    label={t('nav.no-link')}
                  />
                  {(popupContentCategory !== 'Single' &&
                    <Fragment>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={popupContentIsDiscount}
                            onChange={(e) => setPopupContentIsDiscount(e.target.checked)}
                          />}
                        label={t('nav.with-discount')}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={popupContentIsBundle}
                            onChange={(e) => setPopupContentIsBundle(e.target.checked)}
                          />}
                        label={t('nav.only-bundles')}
                      />
                    </Fragment>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} className={classes.verticalAlign}>
                <Typography variant="caption" color="initial">{t('nav.menu-item-url')}</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <FormControl className={classes.formControl}>
                  <TextField
                    placeholder="URL auto generada"
                    id="uiUrl" name="uiUrl" color="primary" disabled value={popupContent?.uiUrl}
                    error={hasError && (!popupContent?.uiUrl)}
                  />
                </FormControl>
              </Grid>
              <div className={classes.actionsWrapper}>
                {hasError && <Typography variant="caption" color="error" >{t('settings.label-errors-msg')}</Typography>}
                <Button
                  variant="outlined"
                  color="secondary"
                  endIcon={<DeleteIcon />}
                  onClick={deleteMenuItem}
                >
                  {t('items.delete')}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  endIcon={<SaveIcon />}
                  onClick={saveMenuItem}
                >
                  {t('items.save')}
                </Button>
              </div>
            </Grid>
          </Paper>
        </Modal>
      </div>
    </Fragment>
  );
};


export default NavigationSettings;
