// React.
import React from 'react';

// Material UI.
import Button from '@material-ui/core/Button';

// Components.
import OrderSearch from '../orders/OrderSearch';
import OrderList from '../orders/OrderList';

// Translations.
import { useTranslation } from 'react-i18next';

import {RootStore} from "../../../Store";

//  JWT decode token.
import jwtDecode from 'jwt-decode';

// Styles
import useStyles from './OrdersStyles';
import TotalsInformation from '../../utils/TotalsInformation';
import { useSelector } from 'react-redux';
import SellersStoreTotals from '../reports/SellersStoreTotals';
import { getProjectConfig } from './../../../getProjectConfig';

const Orders = () => {
  const [t] = useTranslation('global');
  const classes = useStyles();
  const authToken = localStorage.token;
  const decodedToken: any = jwtDecode(authToken);

  const orderState = useSelector((state: RootStore) => state.order);

  const {
    orderList,
  } = orderState;

  const isSeller = decodedToken.auth.includes('ROLE_SELLER')
  const isAdmin = decodedToken.auth.includes('ROLE_ADMIN')
  const isSinglesAsoc = (!decodedToken.auth.includes('ASSOCIATE') && !decodedToken.auth.includes('ROLE_ADMIN_SINGLES'))

  return (
    <div className={ classes.root }>
      <div className={ classes.summaries }>

        {(isSeller && orderList?.sellerSummary ) ?
          <TotalsInformation
            totalWeb={orderList?.sellerSummary[orderList.sellerSummary.length - 1]?.totalWeb ?? 0} 
            totalSeller={orderList?.sellerSummary[orderList.sellerSummary.length - 1]?.totalSeller ?? 0}
            totalPackage={orderList?.sellerSummary[orderList.sellerSummary.length - 1]?.totalPackage ?? 0}
            finalTotal={orderList?.sellerSummary[orderList.sellerSummary.length - 1]?.finalTotal ?? 0}
          /> : null
        }

        {(isSeller && orderList?.sellerSummary ) ?
          <SellersStoreTotals
            sellerSummaries={orderList?.sellerSummary || []}
          /> : null
        }

      </div>
      { isSinglesAsoc
        ? 
          <Button variant="contained" color="primary" href={'/admin/add-order'}>
          {t('orders.create-new-admin-order')}
        </Button>
        : null
      }
      {
        (isSeller || isAdmin) && <Button variant="contained" color="primary" href={getProjectConfig().SD_URL} target="_blank">
        {t('orders.store-delivery-url')}
      </Button>
      }
      <OrderSearch />
      <OrderList />
    </div>
  )
}

export default Orders;
