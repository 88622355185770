import { Seller, OrderBundleType, OrderRefundBundleType, BundleType } from '../../components/admin/models/ModelTypes';
import { ItemType } from '../itemActions/ItemActionsTypes';

/**
 *****************************************
 *********** Dispatch Types **************
 *****************************************
 */
export const ADD_ITEM_LIST_UPDATE = "ADD_ITEM_LIST_UPDATE";
export const ORDER_LOADING = "ORDER_LOADING";
export const ORDER_FAIL = "ORDER_FAIL";
export const ADD_ORDER = "ADD_ORDER";
export const ORDER_LIST = "ORDER_LIST";
export const COMPLETED_ORDERS_AMMOUNT = 'COMPLETED_ORDERS_AMMOUNT'
export const CREATED_ORDERS_AMMOUNT = 'CREATED_ORDERS_AMMOUNT'
export const ORDER_PENDING_LIST = "ORDER_PENDING_LIST";
export const ORDER_STATUS_LIST = "ORDER_STATUS_LIST";
export const ORDER_REFRESH = "ORDER_REFRESH";
export const ORDER_SET_FILTER = "ORDER_SET_FILTER";
export const ADD_REFUND = "ADD_REFUND";
export const UPDATE_ORDER = "UPDATE_ORDER";
export const GET_SHIPPINGS = "GET_SHIPPINGS";
export const ASSOCIATE_ORDER_REPORT = "ASSOCIATE_ORDER_REPORT";
export const PENDING_ORDER_LIST = "PENDING_ORDER_LIST";
export const ORDER_TO_DELIVER_LIST = "ORDER_TO_DELIVER_LIST";
export const UPDATE_ORDER_DELIVER = "UPDATE_ORDER_DELIVER";
export const REGISTER_ORDER_NOTE = "REGISTER_ORDER_NOTE";
export const REGISTER_ORDER_COST = "REGISTER_ORDER_COST";

/**
 *****************************************
 ************* Object Types **************
 *****************************************
 */
export type OrderStatusType = {
  orderStatuses: string[]
};

export type OrderCostList={
  totalCost: number
}

export type ItemAddedType = {
  item: any,
  quantity: number,
  customReference: string,
  whId: number,
};

export type BundleAddedType = {
  bundle: BundleType,
  quantity: number,
};

export type ItemAddedListType = {
  itemAddedList: ItemAddedType[],
  bundleAddedList: BundleAddedType[]
};

export type OrderCurrencyType = {
  id: number,
  name: string,
  symbol: string,
  code: string,
};

export type OrderAddressType = {
  id: number,
  province: string,
  canton: string,
  district: string,
  phoneNumber: string,
  postalCode: string,
  fullAddress: string
};

export type OrderShippingType = {
  id: number,
  cost: number,
  max_days: number,
  description: string,
  name: string,
  isActive: boolean,
  currency: OrderCurrencyType
};

export type ItemWarehouseRelationType = {
  warehouseId: number,
  itemId: number,
  customReference?: string
};

export type ItemWarehouseEntityType = {
  name: string,
  description: string,
  isDiscount: boolean
};

export type ItemWarehouseType = {
  id: ItemWarehouseRelationType,
  quantity: number
  warehouse: ItemWarehouseEntityType
};

export type CustomPrice = {
  price: number,
  customReference: string
}

export type OrderItemLabelValues = {
  id: number,
  isDeleted: boolean,
  value: string
};

export type OrderItemEntityType = {
  id: number,
  name: string,
  code: string,
  category: string,
  customPrices?: Array<CustomPrice>,
  warehousesCustomQuantities?: Array<ItemWarehouseType>,
  priceWithTaxes: number,
  discountPriceWithTaxes?: number
  images: Array<any>,
  labelValues?: OrderItemLabelValues[]
}

export type OrderItemType = {
  normal_quantity: number,
  discount_quantity: any,
  normal_price: number,
  administrativeCost: number,
  conditionCost: number,
  discount_price: any,
  itemTypeTax: number,
  item: OrderItemEntityType
};

export type OrderRefundItemType = {
  status: string,
  normal_quantity: number,
  discount_quantity: any,
  normal_price: number,
  discount_price: any,
  isPaymentReflected: boolean,
  item: ItemType
};

export type OrderVendorType = {
  id: number,
  name: string,
  is_tim: boolean,
  isDeleted: boolean
};

export type OrderNoteType = {
  content: string,
  creationDate: string,
  id: number,
  userEmail: string,
};

export type OrderType = {
  id: number,
  orderCode: string,
  creationDate: string,
  updatedDate: string,
  completedDate: string,
  processedDate: string,
  totalCost: number,
  totalTax: number,
  shippingCost: number,
  status: string,
  type: string,
  purchaseValidation: string,
  trackingNumber: any,
  isDeleted: boolean,
  seller: Seller,
  sfTax: number,
  user: any,
  currency: OrderCurrencyType,
  address: OrderAddressType,
  addressName: string,
  shipping: OrderShippingType,
  orderItems: OrderItemType[],
  orderBundleItems: OrderBundleType[],
  refundItems: OrderRefundItemType[],
  refundBundles: OrderRefundBundleType[],
  vendor: OrderVendorType,
  warehouseRemovalItems: Array<any>,
  conversation: Array<any>,
  paymentMethod: string,
  orderNotes?: Array<OrderNoteType>,
  points: number,
  pickupId: string
};

export type OrderList = {
  first: boolean
  last: boolean
  number: number
  size: number
  totalPages: number
  processedDate: string
  orders: OrderType[]
  totalElements: number
  sellerSummary: Array<any>
};

export type OrderPendingList = {
  first: boolean
  last: boolean
  number: number
  size: number
  totalPages: number
  orders: OrderType[]
};

export type ShippingType = {
  cost: number,
  currency: any,
  description: string,
  id: number,
  isActive: boolean,
  isDeleted: boolean,
  max_days: number,
  name: string
};

export type AddResponseType = {
  order: Array<OrderType>,
  msg: string
};

export type AssociateReportDataType = {
  id: number,
  totalCommision: number,
  totalOrdersProcessed: number,
  totalPriceOrders: number,
  totalToPay: number,
  vendorName: string,
  details: string
}

export type OrderAssociateReportType = {
  associateReportDataList: AssociateReportDataType[],
  totalCommission: number,
  totalPriceOrders: number,
  totalToPay: number
};

export type OrderToDeliverType = {
  code: string,
  orderId: number,
  status: string,
  creationDate: string,
  customerName: string,
  completedBy: string,
  completedDate: string,
  processedBy: string,
  processedByDate: string,
  readyPickupBy: string,
  readyPickupByDate: string,
  notes: OrderNoteType[],
  pickUpId: string,
};

export type OrderToDeliverList = {
  first: boolean
  last: boolean
  number: number
  size: number
  totalPages: number
  ordersList: OrderToDeliverType[]
  totalElements: number
};

/**
 *****************************************
 *********** Interfaces Types ************
 *****************************************
 */
export interface OrderAddItemListUpdate {
  type: typeof ADD_ITEM_LIST_UPDATE
  payload: ItemAddedListType
};

export interface OrderLoading {
  type: typeof ORDER_LOADING
  payload: string
}

export interface OrderFail {
  type: typeof ORDER_FAIL,
  payload: string
}

export interface OrderAdd {
  type: typeof ADD_ORDER,
  payload: AddResponseType
}

export interface OrderAddRefud {
  type: typeof ADD_REFUND,
  payload: string
}

export interface OrderUpdate {
  type: typeof UPDATE_ORDER,
  payload: string
}

export interface OrderListType {
  type: typeof ORDER_LIST,
  payload: OrderList
};

export interface OrderToDeliverListType {
  type: typeof ORDER_TO_DELIVER_LIST,
  payload: OrderToDeliverList
};

//For the Order Modal

export interface OrderCostType {
  type: typeof REGISTER_ORDER_COST,
  payload: number
}

// For report page.
export interface PendingOrderListType {
  type: typeof PENDING_ORDER_LIST,
  payload: OrderList
};

export interface OrderPendingListType {
  type: typeof ORDER_PENDING_LIST,
  payload: OrderPendingList
};

export interface OrderStatusListType {
  type: typeof ORDER_STATUS_LIST,
  payload: OrderStatusType
};

export interface OrderRefresh {
  type: typeof ORDER_REFRESH,
  payload: boolean
};

export interface OrderSetFilter {
  type: typeof ORDER_SET_FILTER,
  payload: object
};

export interface ShippingListType {
  type: typeof GET_SHIPPINGS,
  payload: Array<ShippingType>
};

export interface UpdateCompletedOrdersAmmount {
  type: typeof COMPLETED_ORDERS_AMMOUNT,
  payload: string
};

export interface UpdateCompletedOrdersCreatedAmmount {
  type: typeof CREATED_ORDERS_AMMOUNT,
  payload: string
};

export interface AssociateReportType {
  type: typeof ASSOCIATE_ORDER_REPORT,
  payload: OrderAssociateReportType
};

export interface OrderDeliverUpdate {
  type: typeof UPDATE_ORDER_DELIVER,
  payload: string
}

export interface RegisterOrderNoteType {
  type: typeof REGISTER_ORDER_NOTE,
  payload: string
}

// Export types.
export type OrderDispatchTypes = OrderAddItemListUpdate | OrderLoading | OrderFail | OrderAdd |
  OrderUpdate | OrderAddRefud | OrderListType | OrderStatusListType | PendingOrderListType |
  OrderRefresh | OrderSetFilter | ShippingListType | OrderPendingListType | OrderToDeliverListType |
  UpdateCompletedOrdersAmmount | UpdateCompletedOrdersCreatedAmmount | AssociateReportType | OrderDeliverUpdate | 
  OrderCostType | 
  RegisterOrderNoteType;
