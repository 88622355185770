import React, { useState, useEffect } from "react";

// Material UI
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


// Redux
import { useDispatch, useSelector } from "react-redux";
import { getItemLabels,registerLabelValues, updateLabelValues, registerLabel, updateLabel } from "../../../actions/itemActions/ItemActions";
import { getItemTypes } from "../../../actions/itemTypeActions/ItemTypeActions";
import { RootStore } from "../../../Store";

// Model Types.
import { LabelType, LabelValueType, ItemTypes } from '../models/ModelTypes';

// Styles.
import useStyles from './LabelsSettingsStyles';

// Translations.
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionDetails, AccordionSummary } from "../orders/OrderAccordionStyles";
import { Pagination } from "@material-ui/lab";
import Modal from "../../utils/modal";
import CreateLabel from "./CreateLabelModal";
import { Delete, Edit } from "@mui/icons-material";
import { deleteLabelValue } from "../../../actions/prizeActions/PrizeActions";
import LabelEditModal from "./LabelsEditModal";
import AddLabelModal from "./AddLabelsModal";

const LabelsSettings = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [t] = useTranslation('global');

  const initialLabel = [{
    id: 0,
    itemTypeId: 0,
    name: '',
    description: '',
    values: [{id: 0, value:''}]
  }];

  // Local state.
  const [labels, setLabels] = useState<Array<LabelType>>(initialLabel);
  const [categoryId, setCategoryId] = useState<number>(1);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize]= useState(10);
  const [pageAmount,setPageAmount]=useState(1);
  const [expanded, setExpanded] = useState<string | false>(false);
  const [modalOpen, setModalOpen]= useState(false);
  const [newLabel,setNewLabel]=useState("");
  const [newLabelValue,setNewLabelValue]= useState<string>("");
  const [newLabelDescription, setNewLabelDescription]=useState("");
  const [showButtons, setShowButtons] = useState(Array(labels.length).fill(false));
  const [labelEditModalOpen, setLabelEditModalOpen]= useState<boolean>(false)
  const [newEditLabelValue,setNewEditLabelValue]= useState<any>("");
  const [newEditLabelValueId,setNewEditLabelValueId]= useState<any>();
  const [addLabelModalOpen, setAddLabelModalOpen]= useState(false);
  const [workingLabelId, setWorkingLabelId]=useState(0);
  const pageSizes=[10,15,30]

  // Redux state.
  const itemsState = useSelector((state: RootStore) => state.item);
  const itemTypeState = useSelector((state: RootStore) => state.itemType);
  const {loading, itemLabelList} = itemsState;
  const itemTypeList = itemTypeState.itemTypeList;
  const categoryLoading = itemTypeState.loading;
  const prizeStateStatus = useSelector((state:RootStore)=>state.prize.actionStatus)

  useEffect(()=>{
    if(itemLabelList!==undefined && itemLabelList[0]!==undefined){
      setPageAmount(itemLabelList[0].totalPages)
    }
    // eslint-disable-next-line
  },[itemsState])

  useEffect(() => {
    dispatch(getItemTypes());
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (itemTypeList !== undefined) {
      if (itemTypeList.categories?.length > 0) {
        let firstCategoryId = itemTypeList.categories[0].id;
        dispatch(getItemLabels(firstCategoryId,page,10));
        setCategoryId(firstCategoryId);
      }
    }
    // eslint-disable-next-line
  }, [itemTypeList])

  useEffect(() => {
    if (itemLabelList !==  undefined) {
      const newArray:any = [];
      itemLabelList.forEach((label:any) =>
        newArray.push({
          id: label.id,
          itemTypeId: categoryId,
          name: label.value,
          description: label.description,
          values: label.labelValues
        })
      );
      setLabels(newArray);
    }
    // eslint-disable-next-line
  }, [itemLabelList])

  const handleLabelNameChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>, index:number) => {
    const name = String(event.target.name);
    var value = String(event.target.value);
    // Update the state.
    const newArray = [...labels];
    newArray[index] = {
      ...newArray[index],
      [name]: value
    };
    setLabels(newArray);
  };

  const handleLabelDescriptionChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>, index:number) => {
    const description = String(event.target.name);
    var value = String(event.target.value);
    // Update the state.
    const newArray = [...labels];
    newArray[index] = {
      ...newArray[index],
      [description]: value
    };
    setLabels(newArray);
  };

  //handle the updates for the parent labels
  const handleUpdateLabel = (index:number)=>{
    let id= labels[index].id;
    let name= labels[index].name;
    let description= labels[index].description;
    let updatedShowButton=[...showButtons]
    updatedShowButton[index]=false;
    setShowButtons(updatedShowButton);
    dispatch(updateLabel(id,name,description,index));
  }

  const handleLabelValuesChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>, parentIndex:number, index:number) => {
    const name = String(event.target.name);
    var value = String(event.target.value);
    // Update the state.
    const newArray = [...labels];
    newArray[parentIndex].values[index] = {
      ...newArray[parentIndex].values[index],
      id: newArray[parentIndex].values[index].id,
      [name]: value
    };

    setLabels(newArray);
  };

  const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (event.target.value) {
      let cId = Number(event.target.value);
      dispatch(getItemLabels(cId,0,pageSize));
      setCategoryId(cId);
    }
  };

  const handleCreatelabel=()=>{
    dispatch(registerLabel(categoryId, newLabel, newLabelDescription, 0));
    setModalOpen(false)
  }

  const handleNewLabel = (event: React.ChangeEvent<{ value: any }>)=>{
    setNewLabel(event.target.value);
  }

  const handleNewLabelDescription = (event: React.ChangeEvent<{ value: any }>)=>{
    setNewLabelDescription(event.target.value);
  }

  //logic to expand the accordion
  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  //Handle de Page size chosen
  const handlePageSizeChange = (newPageSize:string) => {
    setPageSize(parseInt(newPageSize))
  }

  //Handle de Pagination
  const handlePaginationChange = (event:any,newPage:any) => {
      setPage(newPage-1);    
  };

  useEffect(()=>{
    dispatch(getItemLabels(categoryId,page,pageSize))
    // eslint-disable-next-line
  },[page,pageSize,prizeStateStatus,itemsState.processedLabelValue])

  // Handle Close Modal
  const handleCloseModal = ()=>{
    setModalOpen(false);
    setLabelEditModalOpen(false);
    setAddLabelModalOpen(false);
    setNewEditLabelValue('');
    setNewLabelValue('');
  }

  //Handle Label Value Delete
  const handleLabelValueDelete = (labelValueId:number)=>{
    dispatch(deleteLabelValue(labelValueId.toString()));
  }

  //handle label value modal open
  
  const handleLabelEditModalOpen =(valueId:number,value:string)=>{
    setNewEditLabelValueId(valueId)
    setLabelEditModalOpen(true);
  } 

  const handleEditlabelValue =(event:any)=>{
    setNewEditLabelValue(event.target.value)
  }

  const handleLabelEdit = () => {
      let id=newEditLabelValueId
      let value= newEditLabelValue
      dispatch(updateLabelValues(id,value))
      handleCloseModal()
  };

  const handleCreateLabelValue=()=>{
    dispatch(registerLabelValues(workingLabelId,newLabelValue))
    handleCloseModal()
  }

  const handleNewLabelValue=(event:any)=>{
    setNewLabelValue(event.target.value)
  }

  const handleAddLabelModal = (labelId:number)=>{
     setAddLabelModalOpen(true);
     setWorkingLabelId(labelId);
  }

  return (
    <Grid container spacing={1} className={classes.root}>
      <Grid item xs={12} sm={12}>
        <Typography variant="h4" color="primary" className={classes.marginBottom}>
          { t('settings.manage-labels') }
          {(categoryLoading !== '' || loading !== '') && <CircularProgress/> }
        </Typography>
        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'100%', marginBottom:'30px'}}>
          <div style={{width:'300px'}}>
          <InputLabel id="category-select-label">{ t('settings.select-parent-category') }</InputLabel>
          <Select
            labelId="category-select-label"
            id="category-select"
            value={categoryId}
            onChange={ handleSelectChange }
          >
            {itemTypeList?.categories.map((category:ItemTypes, index:number) =>
              <MenuItem value={category.id} key={index}>{category.name}</MenuItem>
            )}
          </Select>
          </div>
          <div style={{display:'flex'}}>     
          <div style={{width:'160px',textAlign:'center', alignItems:'center',display:'flex'}}>Resultados por pagina:</div>
            <Select
              labelId="result-select-label"
              id="result-select"
              style={{width:'60px'}}
              value={pageSize}
              onChange={(e) => handlePageSizeChange(`${e.target.value}`)} 
            >
              {pageSizes.map(
                (pageSize) => <MenuItem
                  key={pageSize}
                  value={pageSize}>
                  {pageSize}
                </MenuItem>
              )}
            </Select>    
            </div>    
        </div>
      </Grid>
      {loading?
      <CircularProgress/>:
      <div style={{width:'100%'}}>
     {labels.map((label:LabelType, index:number) =>{
      return(
      <Accordion key={ label.id } style={{width:'100%'}} square expanded={expanded ===`panel-u-${ label.id }`} onChange={handleChange(`panel-u-${ label.id }`)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel-content" id="panel-header">
        <Grid container spacing={2}>
          <Grid item xs={9} sm={4}>
            <div onClick={e=>e.stopPropagation()}>
          <TextField 
                name="name"
                label={ <Typography color="primary">{t('settings.label-name')}</Typography> }
                value={label.name}
                onChange={(e) => {
                  handleLabelNameChange(e, index)
                  setShowButtons(prevState => {
                    const updatedState = [...prevState];
                    updatedState[index] = true;
                    return updatedState;
                  });
                }}
                className="label"
              />
              </div>
          </Grid>
          <Grid item xs={9} sm={4}>
          <div onClick={e=>e.stopPropagation()}>
          <TextField
                name="description" 
                label={ <Typography color="primary">{t('settings.label-description')}</Typography> }
                value={label.description?label.description:"Sin descripcion"}
                onChange={(e) => {
                  handleLabelDescriptionChange(e, index)
                  setShowButtons(prevState => {
                    const updatedState = [...prevState];
                    updatedState[index] = true;
                    return updatedState;
                  });
                }}
                type="text"
                className="description"
              />
              </div>
          </Grid>
          <Grid item xs={9} sm={1}>
          <TextField
                disabled 
                name="description" 
                label={ <Typography color="primary">id</Typography> }
                value={label.id}
                type="text"
                className="description"
                InputProps={{ disableUnderline: true }}
              />
          </Grid>
          <Grid item xs={9} sm={2}>
          {showButtons[index]?
          <Button variant="contained" color="primary" component="span" onClick={ (e:any)=>{
            e.stopPropagation()
            handleUpdateLabel(index)
            } }>
            { t('settings.save') }
          </Button>:
          null}     
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails style={{display:'flex', flexWrap:'wrap'}}>
      {label.values.map((labelValue:LabelValueType, i:number) => {
                let isDisabled:boolean;
                label.values.length<=1?isDisabled=true:isDisabled=false;
                return(
                <Paper elevation={2} key={i} style={{display:'flex', alignItems:'center'}}>
                  <TextField 
                    name="value"
                    label={ t('settings.label-value') }
                    value={labelValue.value}
                    onChange={(e) => handleLabelValuesChange(e, index, i) }
                    className="labelValue"
                    style={{width:'90%'}}
                    InputProps={{
                      readOnly: true
                    }}
                  />
                  <Edit onClick={()=>{
                    handleLabelEditModalOpen(labelValue.id,labelValue.value)
                  }}></Edit>
                  <Delete color={isDisabled?"disabled":"inherit"} onClick={()=>isDisabled?null:handleLabelValueDelete(labelValue.id)}/>
                </Paper>
                )
                }
              )}
      </AccordionDetails>
      <div className={classes.valueButton} style={{marginTop:'20px',marginBottom:'20px',gap:'15px',display:'flex', flexDirection:"column"}}>
        {(categoryLoading !== '' || loading !== '') && <CircularProgress className="loading-bottom"/> }
        <Button variant="contained" color="default" component="span" onClick={ (event:any) => {
            event.stopPropagation();
            handleAddLabelModal(label.id)
           }
           }>
           { t('settings.add-label-value') }
        </Button>
      </div>
      </Accordion>          
      )
     })}                 
      <Grid item xs={12} sm={12} className={classes.marginBottom}>
        <Button variant="contained" color="default" component="span" onClick={()=>setModalOpen(true)}>
          { t('settings.add-label') }
        </Button>
      </Grid>
      <Pagination 
      style={{display:'flex', justifyContent:'center', width:'100%'}} 
      onChange={(event:any, newPage:any)=>{return handlePaginationChange(event,newPage)}}
      count={pageAmount}
      page={page+1}
      ></Pagination>
      </div>
      }
      {modalOpen?
      <Modal children={
        <CreateLabel 
        closeModal={handleCloseModal} 
        handleCreateLabel={handleCreatelabel} 
        handleNewLabel={handleNewLabel} 
        handleNewLabelDescription={handleNewLabelDescription}
        newLabel="lol"/>
      }></Modal>:
      null  
    }
    {labelEditModalOpen?
    <Modal children={
      <LabelEditModal
        closeModal={handleCloseModal}
        newEditLabelValue={newEditLabelValue}
        handleLabelEdit={handleLabelEdit}
        handleEditlabelValue={handleEditlabelValue}
      />}></Modal>:null
      }
      {addLabelModalOpen?
      <Modal children={
        <AddLabelModal 
        closeModal={handleCloseModal}
        handleCreateLabel={handleCreateLabelValue}
        handleNewLabel={handleNewLabelValue}
        newLabel={newLabelValue}
        />
      }/>:null  
    }
    </Grid>
  );
};


export default LabelsSettings;