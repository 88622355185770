// React.
import React, {Fragment, useState, useEffect} from 'react';
import { useLocation } from 'react-router-dom';

// Material UI.
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Modal from '@material-ui/core/Modal';
import CircularProgress from '@material-ui/core/CircularProgress';

// Redux
import { useDispatch, useSelector } from "react-redux";
import { loginUser, logoutUser, forgotPassword, validateUserEmail } from "../../actions/authActions/AuthActions";
import { RootStore } from "../../Store";

// Components.
import LoginFormText from './LoginFormText';
import FBLoginButton from './social/FBLoginButton';
import GoogleLoginButton from './social/GoogleLoginButton'; 

// Styles.
import useStyles from './LoginFormStyles';

// Translations.
import { useTranslation } from 'react-i18next'

//  JWT decode token.
import jwtDecode from 'jwt-decode';

// Analitycs Hook
import useAnalyticsEventTracker from '../../hooks/useAnalyticsEventTracker';

const LoginForm = () => {
  const classes = useStyles();
  const [t] = useTranslation('global');
  const location = useLocation() as any;

  // Anaitycs
  const gaEventTracker = useAnalyticsEventTracker('Contact us');

  // Redux state.
  const dispatch = useDispatch();
  const reduxState = useSelector((state: RootStore) => state.auth);
  const loading = reduxState.loading;
  const loginObject = reduxState.loginObject;
  const actionStatus = reduxState.actionStatus;

  // Local State.
  const [openModal, setOpenModal] = useState(false);
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<boolean>(false);
  const [forgotEmail, setForgotEmail] = useState<string>('');
  const [forgotEmailError, setForgotEmailError] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<boolean>(false);
  const [loggedIn, setLoggedIn] = useState<string>('');
  const [validatingForgotEmail, setValidatingForgotEmail] = useState<boolean>(false);

  useEffect(() => {
    // Check if the user is already logged in.
    const authToken = localStorage.token;
    if (authToken !== undefined && authToken !== '') {
      const decodedToken:any = jwtDecode(authToken);
      setLoggedIn(decodedToken.sub);
    }
    
    // eslint-disable-next-line
  }, []);

  if (actionStatus !== undefined) {
    if (actionStatus.action === 'validate-account') {
      window.location.replace('/user/validate-account');
    }
  }

  // Redirect to the page after login.
  if (loginObject !== undefined) {
    const user = loginObject.user;
    if (user.isResetPassword && !user.isSocialLogin) {
      window.location.replace('/user/reset-password-temporal');
    } else {
      if (location.state !== undefined) {
        window.location.replace(String(location.state.from.pathname));
      } else {
        if (user.roles === 'ROLE_USER') {
          window.location.replace('/');
        } else {
          window.location.replace('/admin');
        }
      }
    }
  }

  const emailChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setEmail(String(event.target.value));
    setEmailError(false);
  }

  const forgotEmailChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setForgotEmail(String(event.target.value));
    setForgotEmailError(false);
  }

  const passwordChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPassword(String(event.target.value));
    setPasswordError(false);
  }

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleOpenModal = () => {
    setOpenModal(true);
  }

  const validateLoginFields = () => {
    var result = true;
    // eslint-disable-next-line
    let emailMask  = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/); 
    if (email === '' || !emailMask.test(email)) {
      result = false;
      setEmailError(true);
    }
    if (password === '') {
      result = false;
      setPasswordError(true);
    }

    return result;
  };

  const validateForgotFields = () => {
    var result = true;
    // eslint-disable-next-line
    let emailMask  = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/); 
    if (forgotEmail === '' || !emailMask.test(forgotEmail)) {
      result = false;
      setForgotEmailError(true);
    }

    return result;
  };

  const loginOnClick = () => {
    gaEventTracker('Login');
    if (validateLoginFields()) {
      dispatch(loginUser(email, password));
      setEmail('');
      setPassword('');
    }
  };

  const logoutOnClick = () => {
    gaEventTracker('Logout');
    dispatch(logoutUser());
    setLoggedIn('');
  };

  useEffect(() => {
    // Send the reset password request.
    if (reduxState.isValidEmail !== undefined) {
      setValidatingForgotEmail(false);
      if (!reduxState.isValidEmail) {
        if (validateForgotFields()) {
          dispatch(forgotPassword(forgotEmail));
          setForgotEmail('');
          setOpenModal(false);
        }
      } else {
        if (loading === '') {
          setForgotEmailError(true);
        }
      }
    }
    
    // eslint-disable-next-line
  }, [reduxState]);

  const handleForgotPassword = () => {
    if (validateForgotFields()) {
      setValidatingForgotEmail(true);
      dispatch(validateUserEmail(forgotEmail));
    }
  };

  const getForgotErrorMessage = () => {
    // eslint-disable-next-line
    let emailMask  = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/); 
    return emailMask.test(forgotEmail) ? t('users.no-existing-email') : t('users.field-required-email'); 
  };

  useEffect(() => {
    // Checking if Enter key has been pressed.
    const keyDownHandler = (event: { key: string; preventDefault: () => void; }) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        const buttonLogin = document.getElementById('login');
        buttonLogin?.click();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  return (
    <div className={ classes.formWrapper }>
      <Typography variant="h5" color="primary">{ t('authentication.log-in') }</Typography>
      <form noValidate autoComplete="off">
        <CssBaseline />
        {loggedIn === '' ?
          <Fragment>
            <div className="form-fields">
              <TextField 
                id="user-email" 
                name="email"
                type="email"
                value={email}
                label={ t('authentication.email') }
                color="primary" 
                fullWidth
                onChange={ emailChange } 
                error={ emailError }
                autoFocus
                helperText={emailError ? t('users.field-required-email'): ''}
              />
              <TextField 
                id="user-password" 
                name="password"
                value={password}
                label={ t('authentication.password') } 
                color="primary" 
                type="password"
                fullWidth 
                onChange={ passwordChange } 
                error={ passwordError }
                helperText={passwordError ? t('users.field-required'): ''}
              />
              { actionStatus?.menssage === 'status.success-reset' &&  
                <Typography variant="subtitle1" color="primary">{ t('authentication.password-sent') }</Typography>
              }
              { actionStatus?.status === 'error' &&  
                <Typography variant="subtitle1" color="secondary">{ t('authentication.incorrect-data') }</Typography>
              }
            </div>
            <div className={ classes.btnNew }>
              <Button href="/user/register" variant="outlined" color="primary"> { t('authentication.create-new-account') } </Button>
              <Button id="login" variant="contained" color="primary" onClick={loginOnClick}> {t('authentication.continue')} </Button>
              <br /><br /><br />
              <Button variant="text" color="primary" onClick={() => handleOpenModal()}> {t('authentication.forgot-password')} </Button>
            </div>
            <div className={ classes.socialLogin }>
              <FBLoginButton />
              <GoogleLoginButton />
            </div>
            <div className={ classes.formText }>
              <LoginFormText />
            </div>
            <div className={classes.formControls}>
              { loading === 'LOGIN_USER' && <CircularProgress /> }
            </div>
          </Fragment>
          :
          <Fragment>
            <Typography variant="subtitle1" color="primary">{ t('authentication.already-logedin', {'username': loggedIn}) }</Typography>
            <br/>
            <Button variant="contained" color="primary" onClick={ logoutOnClick }> { t('authentication.log-out') } </Button>
          </Fragment>
        }
      </form>
      <Modal
        open={ openModal }
        onClose={ handleCloseModal }
        aria-labelledby="Forgot Password"
        >
        <Paper elevation={3} className={classes.modalPaper}>
          <Typography variant="h5" color="primary">{ t('authentication.forgot-password') }</Typography>
          <div className={ classes.modalField }>
            <Typography variant="subtitle1" color="textSecondary">{ t('authentication.reset-label') }</Typography>
            <TextField 
              id="user-email" 
              name="email"
              type="email"
              label={ t('authentication.email') }
              color="primary" 
              fullWidth
              autoFocus
              onChange={ forgotEmailChange } 
              error={ forgotEmailError }
              helperText={forgotEmailError ? getForgotErrorMessage() : ''}
            />
          </div>
          <Button variant="contained" color="primary" onClick={() => handleForgotPassword() } className={ classes.modalButton } disabled={validatingForgotEmail || forgotEmailError}> { t('authentication.submit') } </Button>
        </Paper>
      </Modal>
    </div>
  )
}

export default LoginForm;
