import {Dispatch} from "redux";
import axios from 'axios';
//  JWT decode token.
import jwtDecode from 'jwt-decode';
import {
  ADD_ITEM_LIST_UPDATE, ORDER_REFRESH, ADD_REFUND, UPDATE_ORDER, ORDER_SET_FILTER, PENDING_ORDER_LIST, ORDER_TO_DELIVER_LIST,
  ORDER_LOADING, ORDER_FAIL, ORDER_STATUS_LIST, ADD_ORDER, ORDER_LIST, GET_SHIPPINGS, ORDER_PENDING_LIST, ASSOCIATE_ORDER_REPORT,
  ItemAddedType, BundleAddedType, ItemAddedListType, OrderDispatchTypes, 
  COMPLETED_ORDERS_AMMOUNT, CREATED_ORDERS_AMMOUNT, UPDATE_ORDER_DELIVER, REGISTER_ORDER_NOTE, REGISTER_ORDER_COST
} from "./OrderActionsTypes";

const apiURL: string | undefined = process.env.REACT_APP_API_URL;
const token: string | undefined = localStorage.token;

/**
 * Update the order added items state.
 * @param {Array<ItemAddedType>} itemAddedList
 * @param {Array<BundleAddedType>} bundleAddedList
 */
export const itemAddedListUpdate = (itemAddedList:ItemAddedType[], bundleAddedList:BundleAddedType[]) => async (dispatch: Dispatch<OrderDispatchTypes>) => {

  const data:ItemAddedListType = {
    itemAddedList: itemAddedList,
    bundleAddedList: bundleAddedList
  };
  
  dispatch({
    type: ADD_ITEM_LIST_UPDATE,
    payload: data
  })
};

/**
 * Update the refresh order view state.
 * @param {boolean} value
 */
export const refreshOrderView = (value: boolean) => async (dispatch: Dispatch<OrderDispatchTypes>) => {
  dispatch({
    type: ORDER_REFRESH,
    payload: value
  })
};

/**
 * Update the order view filters state.
 * @param {object} filters
 */
export const orderSetFilters = (filters: object) => async (dispatch: Dispatch<OrderDispatchTypes>) => {
  dispatch({
    type: ORDER_SET_FILTER,
    payload: filters
  })
};

/**
 * Create a order on the BE.
 * @param {any} orderElements
 * @param {string} paymentMethod
 * @param {any} userEmail
 * @param {number} shippingId
 * @param {number} addressId
 * @param {string} selectedVendor
 */
export const addOrder = (orderElements: any, paymentMethod: string, userEmail: string, shippingId: number, addressId: number, selectedVendor: string) => async (dispatch: Dispatch<OrderDispatchTypes>) => {
  if (apiURL !== '' && token !== '') {

    try {
      dispatch({
        type: ORDER_LOADING,
        payload: 'ADD_ORDER'
      })

      const bodyForm = {
        ...orderElements,
        paymentMethod: paymentMethod,
        shippingId: shippingId,
        ...(addressId !== 0) && {addressId: addressId},
        ...(userEmail) && {userEmail: userEmail},
        ...(selectedVendor) && { vendorIdShipping: selectedVendor }
      };

      const configPOST = {
        headers: {
          'Accept': '*/*',
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        }
      };
    
      const addRes = await axios.post(`${apiURL}/seller/registerOrder`, bodyForm, configPOST);
 
      if (addRes.status === 200) {
        dispatch({
          type: ADD_ORDER,
          payload: {order: addRes.data.data, msg: 'status.success-create'}
        })
      }
       
    } catch(e) {
      dispatch({
        type: ORDER_FAIL,
        payload: 'status.create-error'
      })
    }
  } else {
    dispatch({
      type: ORDER_FAIL,
      payload: 'status.create-error'
    })
  }
};

//Send the total cost of the orders to redux

export const getTotalPrice = (total:string) => async (dispatch: Dispatch<OrderDispatchTypes>) => {
  const userToken: string | undefined = localStorage.token;
    if (apiURL !== '' && userToken !== ''){
      try{
        const cost =  Number(total)
        dispatch({
          type: REGISTER_ORDER_COST,
          payload:cost
        })
      }catch(err){
      }
    }
}

/**
 * Get a list of orders from the BE, it can be filtered.
 * @param {object|null} qParams
 */
export const getOrders = (qParams: object|null) => async (dispatch: Dispatch<OrderDispatchTypes>) => {

  if (apiURL !== '' && token !== '') {
    try {
      dispatch({
        type: ORDER_LOADING,
        payload: 'LIST'
      })

      const headers: object = {
        ContentType: 'application/json',
        Authorization: 'Bearer ' + token
      }

      const res = await axios.get(`${getBasePath(['admin-singles', 'seller', 'admin'])}/getAllOrders`, {
        params: qParams,
        headers: headers
       });
       if (res.status === 200) {
        const responseData = res.data.data
        const queryData = responseData.orderInformation
          ? responseData.orderInformation.content
          : responseData.content;
        
        const isSeller = responseData.orderInformation?.totalPages !== undefined;

        const orders:any = [];
        for(var i = 0; i < queryData.length; i++) {
          const order = queryData[i];
          orders.push({
            id: order.id,
            orderCode: order.orderCode,
            creationDate: order.creationDate,
            updatedDate: order.updatedDate,
            completedDate: order.completedDate,
            totalCost: order.totalCost,
            totalTax: order.totalTax,
            shippingCost: order.shippingCost,
            status: order.status,
            type: order.type,
            purchaseValidation: order.purchaseValidation,
            trackingNumber: order.trackingNumber,
            isDeleted: order.isDeleted,
            sfTax: order.sfTax,
            user: order.user,
            currency: order.currency,
            address: order.address,
            addressName: order.addressName,
            shipping: order.shipping,
            orderItems: order.orderItems,
            orderBundleItems: order.orderBundleItems,
            refundItems: order.refundItems,
            refundBundles: order.refundBundles,
            seller: order.seller,
            vendor: order.vendor,
            warehouseRemovalItems: order.warehouseRemovalItems,
            conversation: order.conversation,
            paymentMethod: order.paymentMethod,
            processedDate: order.processedDate,
            orderNotes: order.orderNotes,
            points: order.points,
            pickupId: order.pickupId,
          });
        }

        const data:any = {
          first: responseData.first,
          last: responseData.last,
          number: responseData.number,
          size: responseData.size,
          totalPages: isSeller 
            ? responseData.orderInformation.totalPages
            : responseData.totalPages,
          totalElements: responseData.totalElements,
          orders: orders,
          sellerSummary: responseData.orderSellerSummaryDataList
        };

        dispatch({
          type: ORDER_LIST,
          payload: data
        })
       } else {
        dispatch({
          type: ORDER_FAIL,
          payload: 'status.get-error'
        })
       }
    } catch(e) {
      dispatch({
        type: ORDER_FAIL,
        payload: 'status.get-error'
      })
    }
  } else {
    dispatch({
      type: ORDER_FAIL,
      payload: 'status.get-error'
    })
  }
};

/**
 * Get a list of pending orders from the BE, it can be filtered.
 * @param {object|null} qParams
 */
export const getAllPendingToPayOrders = (qParams: object | null) => async (dispatch: Dispatch<OrderDispatchTypes>) => {

  if (apiURL !== '' && token !== '') {
    try {
      dispatch({
        type: ORDER_LOADING,
        payload: 'PENDING_LIST'
      })

      const headers: object = {
        ContentType: 'application/json',
        Authorization: 'Bearer ' + token
      }

      const res = await axios.get(`${apiURL}/admin/getAllPendingToPayOrders`, {
        params: qParams,
        headers: headers
      });

      if (res.status === 200) {
        const queryData = res.data.data.content;
        const orders: any = [];

        for (var i = 0; i < queryData.length; i++) {
          const order = queryData[i];
          orders.push({
            id: order.id,
            orderCode: order.orderCode,
            creationDate: order.creationDate,
            updatedDate: order.updatedDate,
            completedDate: order.completedDate,
            totalCost: order.totalCost,
            totalTax: order.totalTax,
            shippingCost: order.shippingCost,
            status: order.status,
            type: order.type,
            purchaseValidation: order.purchaseValidation,
            trackingNumber: order.trackingNumber,
            isDeleted: order.isDeleted,
            sfTax: order.sfTax,
            user: order.user,
            currency: order.currency,
            address: order.address,
            addressName: order.addressName,
            shipping: order.shipping,
            orderItems: order.orderItems,
            orderBundleItems: order.orderBundleItems,
            refundItems: order.refundItems,
            refundBundles: order.refundBundles,
            seller: order.seller,
            vendor: order.vendor,
            warehouseRemovalItems: order.warehouseRemovalItems,
            conversation: order.conversation,
            paymentMethod: order.paymentMethod,
            processedDate: order.processedDate,
            orderNotes: order.orderNotes,
            points: order.points
          });
        }

        const data: any = {
          first: res.data.data.first,
          last: res.data.data.last,
          number: res.data.data.number,
          size: res.data.data.size,
          totalPages: res.data.data.totalPages,
          totalElements: res.data.data.totalElements,
          orders: orders
        };

        dispatch({
          type: PENDING_ORDER_LIST,
          payload: data
        })
      } else {
        dispatch({
          type: ORDER_FAIL,
          payload: 'status.get-error'
        })
      }
    } catch (e) {
      dispatch({
        type: ORDER_FAIL,
        payload: 'status.get-error'
      })
    }
  } else {
    dispatch({
      type: ORDER_FAIL,
      payload: 'status.get-error'
    })
  }
};

/**
 * Get a list of pending to deliver orders.
 * @param {object|null} qParams
 */
export const getOrdersToDeliver = (qParams: object | null) => async (dispatch: Dispatch<OrderDispatchTypes>) => {

  if (apiURL !== '' && token !== '') {
    try {
      dispatch({
        type: ORDER_LOADING,
        payload: 'TO_DELIVER_LIST'
      })

      const headers: object = {
        ContentType: 'application/json',
        Authorization: 'Bearer ' + token
      }

      let decodedToken: any = jwtDecode(String(token));
      let rolePath = decodedToken.auth.includes('ROLE_ADMIN_SINGLES') ? 'admin-singles' : 'seller';
      const res = await axios.get(`${apiURL}/${rolePath}/getOrdersDeliver`, {
        params: qParams,
        headers: headers
      });

      if (res.status === 200) {
        const queryData = res.data.data.content;

        const data: any = {
          first: res.data.data.first,
          last: res.data.data.last,
          number: res.data.data.number,
          size: res.data.data.size,
          totalPages: res.data.data.totalPages,
          totalElements: res.data.data.totalElements,
          ordersList: queryData
        };

        dispatch({
          type: ORDER_TO_DELIVER_LIST,
          payload: data
        })
      } else {
        dispatch({
          type: ORDER_FAIL,
          payload: 'status.get-error'
        })
      }
    } catch (e) {
      dispatch({
        type: ORDER_FAIL,
        payload: 'status.get-error'
      })
    }
  } else {
    dispatch({
      type: ORDER_FAIL,
      payload: 'status.get-error'
    })
  }
};

/**
 * Update the orders to deliver information on the BE.
 * @param {any} data
 */
export const updateOrderDeliver = (data: any) => async (dispatch: Dispatch<OrderDispatchTypes>) => {
  if (apiURL !== '' && token !== '') {

    try {
      dispatch({
        type: ORDER_LOADING,
        payload: 'UPDATE_ORDER_DELIVERY'
      })

      const configPUT = {
        headers: {
          'Accept': '*/*',
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        }
      };

      let decodedToken: any = jwtDecode(String(token));
      let rolePath = decodedToken.auth.includes('ROLE_ADMIN_SINGLES') ? 'admin-singles' : 'seller';
      const addRes = await axios.put(`${apiURL}/${rolePath}/updateOrderDeliver`, data, configPUT);

      if (addRes.status === 200) {
        let customValidation = (addRes.data.data.status === data.status);
        if (customValidation) {
          dispatch({
            type: UPDATE_ORDER_DELIVER,
            payload: 'status.success-update'
          })
        } else {
          dispatch({
            type: ORDER_FAIL,
            payload: 'status.no-validation-code'
          })
        }
      }
    } catch (e) {
      dispatch({
        type: ORDER_FAIL,
        payload: 'status.error-update'
      })
    }
  } else {
    dispatch({
      type: ORDER_FAIL,
      payload: 'status.error-update'
    })
  }
};

/**
 * Get a list of orders from the BE for the reports module, it can be filtered.
 * @param {object|null} qParams
 */
 export const getAllOrdersForReports = (qParams: object|null) => async (dispatch: Dispatch<OrderDispatchTypes>) => {
  if (apiURL !== '' && token !== '') {
    try {
      dispatch({
        type: ORDER_LOADING,
        payload: 'LIST'
      })

      const headers: object = {
        ContentType: 'application/json',
        Authorization: 'Bearer ' + token
      }

      const res = await axios.get(`${apiURL}/seller/getAllOrdersForReports`, {
        params: qParams,
        headers: headers
       });

       if (res.status === 200) {
          const queryData = res.data.data.page.content;
          const orders:any = [];

        for(var i = 0; i < queryData.length; i++) {
          const order = queryData[i];
          orders.push({
            id: order.id,
            orderCode: order.orderCode,
            creationDate: order.creationDate,
            updatedDate: order.updatedDate,
            completedDate: order.completedDate,
            totalCost: order.totalCost,
            totalTax: order.totalTax,
            shippingCost: order.shippingCost,
            status: order.status,
            type: order.type,
            purchaseValidation: order.purchaseValidation,
            trackingNumber: order.trackingNumber,
            isDeleted: order.isDeleted,
            sfTax: order.sfTax,
            user: order.user,
            currency: order.currency,
            address: order.address,
            addressName: order.addressName,
            shipping: order.shipping,
            orderItems: order.orderItems,
            orderBundleItems: order.orderBundleItems,
            refundItems: order.refundItems,
            refundBundles: order.refundBundles,
            seller: order.seller,
            vendor: order.vendor,
            warehouseRemovalItems: order.warehouseRemovalItems,
            conversation: order.conversation,
            paymentMethod: order.paymentMethod,
            processedDate: order.processedDate,
            orderNotes: order.orderNotes,
            points: order.points
          });
        }

        const data:any = {
          first: res.data.data.page.first,
          last: res.data.data.page.last,
          number: res.data.data.page.number,
          size: res.data.data.page.size,
          totalPages: res.data.data.page.totalPages,
          totalElements: res.data.data.page.totalElements,
          orders: orders,
          sellerSummary: res.data.data.orderSellerSummaryDataList
        };

        dispatch({
          type: ORDER_LIST,
          payload: data
        })

        dispatch({
          type: COMPLETED_ORDERS_AMMOUNT,
          payload: res.data.data.totalPriceOrdersCompleted
        })

        dispatch({
          type: CREATED_ORDERS_AMMOUNT,
          payload: res.data.data.totalPriceOrders
        })
       } else {
        dispatch({
          type: ORDER_FAIL,
          payload: 'status.get-error'
        })
       }
    } catch(e) {
      dispatch({
        type: ORDER_FAIL,
        payload: 'status.get-error'
      })
    }
  } else {
    dispatch({
      type: ORDER_FAIL,
      payload: 'status.get-error'
    })
  }
};

/**
 * Get a list of associate orders from the BE for the reports module, it can be filtered.
 * @param {object|null} qParams
 */
export const getAllAssociateOrdersForReports = (qParams: object | null) => async (dispatch: Dispatch<OrderDispatchTypes>) => {
  if (apiURL !== '' && token !== '') {
    try {
      dispatch({
        type: ORDER_LOADING,
        payload: 'ASSOCIATE_LIST'
      })

      const headers: object = {
        ContentType: 'application/json',
        Authorization: 'Bearer ' + token
      }

      const res = await axios.get(`${getBasePath(['seller', 'admin'], true)}/getAllAssociateOrdersForReports`, {
        params: qParams,
        headers: headers
      });

      if (res.status === 200) {
        const queryData = res.data.data
        var dataRow = [];
        
        // Format the data.
        for (var i = 0; i < queryData.associateReportDataList.length; i++) {
          const row = queryData.associateReportDataList[i];
          dataRow.push({
            id: i,
            totalCommision: row.totalCommision,
            totalOrdersProcessed: row.totalOrdersProcessed,
            totalPriceOrders: row.totalPriceOrders,
            totalToPay: row.totalToPay,
            vendorName: row.vendorName,
            details: row.vendorName
          });
        }

        const resObj = {
          associateReportDataList: dataRow,
          totalCommission: queryData.totalCommission,
          totalPriceOrders: queryData.totalPriceOrders,
          totalToPay: queryData.totalToPay
        }

        dispatch({
          type: ASSOCIATE_ORDER_REPORT,
          payload: resObj
        })
      } else {
        dispatch({
          type: ORDER_FAIL,
          payload: 'status.get-error'
        })
      }
    } catch (e) {
      dispatch({
        type: ORDER_FAIL,
        payload: 'status.get-error'
      })
    }
  } else {
    dispatch({
      type: ORDER_FAIL,
      payload: 'status.get-error'
    })
  }
};


/**
 * Get a list of orders from the BE, it can be filtered.
 * @param {object|null} qParams
 */
 export const getPendingOrders = (qParams: object|null) => async (dispatch: Dispatch<OrderDispatchTypes>) => {

  if (apiURL !== '' && token !== '') {
    try {
      dispatch({
        type: ORDER_LOADING,
        payload: 'PENDING_LIST'
      })

      const headers: object = {
        ContentType: 'application/json',
        Authorization: 'Bearer ' + token
      }

      const res = await axios.get(`${apiURL}/seller/getAllOrders`, {
        params: qParams,
        headers: headers
       });

       if (res.status === 200) {
          const queryData = res.data.data.content;
          const orders:any = [];

        for(var i = 0; i < queryData.length; i++) {
          const order = queryData[i];
        
          orders.push({
            id: order.id,
            creationDate: order.creationDate,
            updatedDate: order.updatedDate,
            completedDate: order.completedDate,
            totalCost: order.totalCost,
            totalTax: order.totalTax,
            shippingCost: order.shippingCost,
            status: order.status,
            type: order.type,
            purchaseValidation: order.purchaseValidation,
            trackingNumber: order.trackingNumber,
            isDeleted: order.isDeleted,
            sfTax: order.sfTax,
            user: order.user,
            currency: order.currency,
            address: order.address,
            shipping: order.shipping,
            orderItems: order.orderItems,
            orderBundleItems: order.orderBundleItems,
            refundItems: order.refundItems,
            refundBundles: order.refundBundles,
            vendor: order.vendor,
            warehouseRemovalItems: order.warehouseRemovalItems,
            conversation: order.conversation,
            orderNotes: order.orderNotes,
            points: order.points
          });
        }

        const data:any = {
          first: res.data.data.first,
          last: res.data.data.last,
          number: res.data.data.number,
          size: res.data.data.size,
          totalPages: res.data.data.orderInformation.totalPages !== undefined 
            ? res.data.data.orderInformation.totalPages
            : res.data.data.totalPages,
          orders: orders
        };

        dispatch({
          type: ORDER_PENDING_LIST,
          payload: data
        })
       } else {
        dispatch({
          type: ORDER_FAIL,
          payload: 'status.get-error'
        })
       }
    } catch(e) {
      dispatch({
        type: ORDER_FAIL,
        payload: 'status.get-error'
      })
    }
  } else {
    dispatch({
      type: ORDER_FAIL,
      payload: 'status.get-error'
    })
  }
};

/**
 * Gets all orders statuses from BE.
 * 
 */
export const getAllOrderStatus = () => async (dispatch: Dispatch<OrderDispatchTypes>) => {

  if (apiURL !== '' && token !== '') {
    try {
      dispatch({
        type: ORDER_LOADING,
        payload: 'STATUS_LIST'
      })

      const headers: object = {
        ContentType: 'application/json',
        Authorization: 'Bearer ' + token
      }
      
      const decodedToken: any = jwtDecode(String(token));
      const rolePath = decodedToken.auth.includes('ROLE_ADMIN_SINGLES') ? 'admin-singles' : 'seller';
   
      const res = await axios.get(`${apiURL}/${rolePath}/getAllOrderStatus`, {
      headers: headers
      });

      if (res.status === 200) {
        var queryData = res.data.data.orderStatuses;

        // Remove some statuses for admin singles.
        if (decodedToken.auth.includes('ROLE_ADMIN_SINGLES')) {
          const filteredData = queryData.filter((item:string) => (item !== 'Pago Pendiente' && item !== 'Completado' && item !== 'Cancelado'));
          queryData = filteredData;
        }
        const statuses:any = {
          orderStatuses: queryData
        };

        dispatch({
          type: ORDER_STATUS_LIST,
          payload: statuses
        })
      } else {
        dispatch({
          type: ORDER_FAIL,
          payload: 'status.get-error'
        })
      }
    } catch(e) {
      dispatch({
        type: ORDER_FAIL,
        payload: 'status.get-error'
      })
    }
  } else {
    dispatch({
      type: ORDER_FAIL,
      payload: 'status.get-error'
    })
  }
};

/**
 * Register a refund items on BE.
 * @param {any} data
 */
export const addRefund = (data: any) => async (dispatch: Dispatch<OrderDispatchTypes>) => {
  if (apiURL !== '' && token !== '') {

    try {
      dispatch({
        type: ORDER_LOADING,
        payload: 'ADD_REFUND'
      })

      const configPOST = {
        headers: {
          'Accept': '*/*',
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        }
      };
    
      const addRes = await axios.post(`${apiURL}/seller/registerRefund`, data, configPOST);

      if (addRes.status === 200) {
        dispatch({
          type: ADD_REFUND,
          payload: 'status.success-create'
        })
      }
       
    } catch(e) {
      dispatch({
        type: ORDER_FAIL,
        payload: 'status.create-error'
      })
    }
  } else {
    dispatch({
      type: ORDER_FAIL,
      payload: 'status.create-error'
    })
  }
};

/**
 * Register a refund items on BE.
 * @param {any} data
 */
 export const addRefundBundle = (data: any) => async (dispatch: Dispatch<OrderDispatchTypes>) => {
  if (apiURL !== '' && token !== '') {

    try {
      dispatch({
        type: ORDER_LOADING,
        payload: 'ADD_REFUND'
      })

      const configPOST = {
        headers: {
          'Accept': '*/*',
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        }
      };
    
      const addRes = await axios.post(`${apiURL}/seller/registerBundleRefund`, data, configPOST);

      if (addRes.status === 200) {
        dispatch({
          type: ADD_REFUND,
          payload: 'status.success-create'
        })
      }
       
    } catch(e) {
      dispatch({
        type: ORDER_FAIL,
        payload: 'status.create-error'
      })
    }
  } else {
    dispatch({
      type: ORDER_FAIL,
      payload: 'status.create-error'
    })
  }
};

/**
 * Update the order information on the BE.
 * @param {any} data
 */
export const updateOrder = (data: any) => async (dispatch: Dispatch<OrderDispatchTypes>) => {
  if (apiURL !== '' && token !== '') {

    try {
      dispatch({
        type: ORDER_LOADING,
        payload: 'UPDATE_ORDER'
      })

      const configPUT = {
        headers: {
          'Accept': '*/*',
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        }
      };
    
      const addRes = await axios.put(`${apiURL}/seller/updateOrder`, data, configPUT);

      if (addRes.status === 200) {
        dispatch({
          type: UPDATE_ORDER,
          payload: 'status.success-update'
        })
      }
       
    } catch(e) {
      dispatch({
        type: ORDER_FAIL,
        payload: 'status.error-update'
      })
    }
  } else {
    dispatch({
      type: ORDER_FAIL,
      payload: 'status.error-update'
    })
  }
};

/**
 * Update the order information on the BE for admin singles.
 * @param {any} data
 */
export const updateOrderForAdminSingles = (data: any) => async (dispatch: Dispatch<OrderDispatchTypes>) => {
  if (apiURL !== '' && token !== '') {

    try {
      dispatch({
        type: ORDER_LOADING,
        payload: 'UPDATE_ORDER'
      })

      const configPUT = {
        headers: {
          'Accept': '*/*',
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        }
      };

      const addRes = await axios.put(`${apiURL}/admin-singles/updateOrder`, data, configPUT);

      if (addRes.status === 200) {
        dispatch({
          type: UPDATE_ORDER,
          payload: 'status.success-update'
        })
      }

    } catch (e) {
      dispatch({
        type: ORDER_FAIL,
        payload: 'status.error-update'
      })
    }
  } else {
    dispatch({
      type: ORDER_FAIL,
      payload: 'status.error-update'
    })
  }
};

/**
 * Update the order information on the BE.
 * @param {any} data
 */
export const updateQuickOrder = (data: any) => async (dispatch: Dispatch<OrderDispatchTypes>) => {
  if (apiURL !== '' && token !== '') {

    try {
      dispatch({
        type: ORDER_LOADING,
        payload: 'UPDATE_ORDER'
      })

      const configPUT = {
        headers: {
          'Accept': '*/*',
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        }
      };

      const updateRes = await axios.put(`${apiURL}/seller/updateOrder`, data, configPUT);

      if (updateRes.status === 200) {
        const updateOrderData2 = {
          orderId: data.orderId,
          purchaseValidation: data.purchaseValidation,
          status: 'Completado',
          trackingNumber: ''
        }

        const updateRes2 = await axios.put(`${apiURL}/seller/updateOrder`, updateOrderData2, configPUT);

        if (updateRes2.status === 200) {
          dispatch({
            type: UPDATE_ORDER,
            payload: 'status.success-update'
          })
        }
      }
    } catch (e) {
      dispatch({
        type: ORDER_FAIL,
        payload: 'status.error-update'
      })
    }
  } else {
    dispatch({
      type: ORDER_FAIL,
      payload: 'status.error-update'
    })
  }
};

/**
 * Get a list of shippings from the BE.
 */
 export const getShippings = () => async (dispatch: Dispatch<OrderDispatchTypes>) => {

  if (apiURL !== '' && token !== '') {
    try {
      dispatch({
        type: ORDER_LOADING,
        payload: 'LIST'
      })

      const headers: object = {
        ContentType: 'application/json',
        Authorization: 'Bearer ' + token
      }

      let requestPath = `${getBasePath(['user','admin'], true)}/getAllShipping`;
      const res = await axios.get(requestPath, {
        headers: headers
       });

       if (res.status === 200) {
          const queryData = res.data.data.content;
          const shippings = [];

        for(var i = 0; i < queryData.length; i++) {
          const shipping = queryData[i];
        
          shippings.push({
            cost: shipping.cost,
            currency: shipping.currency,
            description: shipping.description,
            id: shipping.id,
            isActive: shipping.isActive,
            isDeleted: shipping.isDeleted,
            max_days: shipping.max_days,
            name: shipping.name
          });
        }

        dispatch({
          type: GET_SHIPPINGS,
          payload: shippings
        })
       } else {
        dispatch({
          type: ORDER_FAIL,
          payload: 'status.get-error'
        })
       }
    } catch(e) {
      dispatch({
        type: ORDER_FAIL,
        payload: 'status.get-error'
      })
    }
  } else {
    dispatch({
      type: ORDER_FAIL,
      payload: 'status.get-error'
    })
  }
};

/**
 * Get a list of user orders from the BE, it can be filtered.
 * @param {object|null} qParams
 */
 export const getUserOrders = (qParams: object|null) => async (dispatch: Dispatch<OrderDispatchTypes>) => {
  const userToken: string | undefined = localStorage.token;
  if (apiURL !== '' && userToken !== '') {
    try {
      dispatch({
        type: ORDER_LOADING,
        payload: 'LIST'
      })

      const headers: object = {
        ContentType: 'application/json',
        Authorization: 'Bearer ' + userToken
      }

      const res = await axios.get(`${apiURL}/user/getAllOrders`, {
        params: qParams,
        headers: headers
       });

       if (res.status === 200) {
          const queryData = res.data.data.content;
          const orders:any = [];

        for(var i = 0; i < queryData.length; i++) {
          const order = queryData[i];
        
          orders.push({
            id: order.id,
            orderCode: order.orderCode,
            creationDate: order.creationDate,
            updatedDate: order.updatedDate,
            completedDate: order.completedDate,
            totalCost: order.totalCost,
            totalTax: order.totalTax,
            shippingCost: order.shippingCost,
            status: order.status,
            type: order.type,
            paymentMethod: order.paymentMethod,
            purchaseValidation: order.purchaseValidation,
            trackingNumber: order.trackingNumber,
            isDeleted: order.isDeleted,
            sfTax: order.sfTax,
            user: order.user,
            currency: order.currency,
            address: order.address,
            addressName: order.addressName,
            shipping: order.shipping,
            orderItems: order.orderItems,
            orderBundleItems: order.orderBundleItems,
            refundItems: order.refundItems,
            refundBundles: order.refundBundles,
            vendor: order.vendor,
            warehouseRemovalItems: order.warehouseRemovalItems,
            conversation: order.conversation,
            points: order.points,
            pickupId: order.pickupId
          });
        }

        const data:any = {
          first: res.data.data.first,
          last: res.data.data.last,
          number: res.data.data.number,
          size: res.data.data.size,
          totalPages: res.data.data.totalPages,
          orders: orders
        };

        dispatch({
          type: ORDER_LIST,
          payload: data
        })
       } else {
        dispatch({
          type: ORDER_FAIL,
          payload: 'status.get-error'
        })
       }
    } catch(e) {
      dispatch({
        type: ORDER_FAIL,
        payload: 'status.get-error'
      })
    }
  } else {
    dispatch({
      type: ORDER_FAIL,
      payload: 'status.get-error'
    })
  }
};

/**
 * Register a note for a order.
 * @param {string} content
 * @param {number} orderId
 */
export const registerOrderNote = (content: string, orderId: number) => async (dispatch: Dispatch<OrderDispatchTypes>) => {
  if (apiURL !== '' && token !== '') {

    try {
      dispatch({
        type: ORDER_LOADING,
        payload: 'REGISTER_ORDER_NOTE'
      })

      const bodyForm = {
        content: content,
        orderId: orderId
      };

      const configPOST = {
        headers: {
          'Accept': '*/*',
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        }
      };

      const decodedToken: any = jwtDecode(String(token));
      const rolePath = decodedToken.auth.includes('ROLE_ADMIN_SINGLES') ? 'admin-singles' : 'seller';
      const addRes = await axios.post(`${apiURL}/${rolePath}/registerOrderNote`, bodyForm, configPOST);

      if (addRes.status === 200) {
        dispatch({
          type: REGISTER_ORDER_NOTE,
          payload: 'status.success-create-note'
        })
      }

    } catch (e) {
      dispatch({
        type: ORDER_FAIL,
        payload: 'status.create-error'
      })
    }
  } else {
    dispatch({
      type: ORDER_FAIL,
      payload: 'status.create-error'
    })
  }
};

export const updateOrderPickupId = (orderId: number, pickupId: string) => async (dispatch: Dispatch<OrderDispatchTypes>) => {
  if (apiURL !== '' && token !== '') {

    try {
      const bodyForm = {
        pickupId: pickupId,
        orderId: orderId
      };

      const configPUT = {
        headers: {
          'Accept': '*/*',
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        }
      };
      const addRes = await axios.put(`${apiURL}/user/updateOrder`, bodyForm, configPUT);

      if (addRes.status === 200) {
        dispatch({
          type: UPDATE_ORDER,
          payload: 'status.success-update'
        })
      }

    } catch (e) {
      dispatch({
        type: ORDER_FAIL,
        payload: 'status.error-update'
      })
    }
  } else {
    dispatch({
      type: ORDER_FAIL,
      payload: 'status.error-update'
    })
  }

}

/**
 * Returns the base path based on the role.
 */
 const getBasePath = (roles:Array<string>, strict:boolean = false) => {
  if (!token) {
    return `${apiURL}/guest`;
  } else {
    const decodedToken:any = jwtDecode(String(token));
    if (decodedToken.auth.includes('ROLE_USER') && roles.includes('user')) {
      return `${apiURL}/user`;
    }
    if (decodedToken.auth.includes('ROLE_ADMIN_SINGLES') && roles.includes('admin-singles')) {
      return `${apiURL}/admin-singles`;
    }
    if ((decodedToken.auth.includes('ROLE_SELLER') || decodedToken.auth.includes('ROLE_SUPER_SELLER')) && roles.includes('seller')) {
      return `${apiURL}/seller`;
    }
    if (strict) {
      if (decodedToken.auth.includes('ROLE_ADMIN') && roles.includes('admin')) {
        return `${apiURL}/admin`;
      }
    } else {
      if ((decodedToken.auth.includes('ROLE_ADMIN') || decodedToken.auth.includes('ROLE_SUPER_SELLER')) && roles.includes('admin')) {
        return `${apiURL}/admin`;
      }
    }
    return `${apiURL}/user`;
  }
};