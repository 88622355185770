import {
  ITEM_FAIL,
  ITEM_LOADING,
  ITEM_PUT,
  ITEM_LIST,
  ITEM_LIST_SEARCH_DATA,
  ITEM_LABEL_LIST,
  ITEM_REFRESH,
  ITEM_SET_FILTER,
  ADD_ITEM,
  DELETE_ITEM,
  ADD_ITEM_DISCOUNT,
  UPDATE_ITEM_DISCOUNT,
  ADD_ITEM_LABEL,
  UPDATE_ITEM_LABEL,
  ADD_ITEM_LABEL_VALUE,
  UPDATE_ITEM_LABEL_VALUE,
  ITEM_LABEL_SAVED,
  ITEM_PRICE_QUANTITY_UPDATE_STATUS,
  ITEM_AUTOMATIC_PRICE_PUT,
  ITEM_SINGLE_PRICE_PUT,
  ITEM_INDIVIDUAL_PUT,
  ITEM_SINGLE_DESC_PUT,
  ITEM_SEARCH_NAMES,
  ITEM_LABEL_BY_TYPE_LIST,
  ITEM_DETAIL,
  ItemDispatchTypes,
  ItemType,
  ItemDatailResponse,
  ItemTypeSearchData,
  ItemList,
  ItemListSearchData,
  ItemLabelsType,
  ItemLabelsByCategoryType,
  ItemLabelResponse,
  ItemSearchNamesResponse,
  ItemLabelValueResponse,
  ItemParticipationList,
  ITEM_PARTICIPATION_LIST
} from "../actions/itemActions/ItemActionsTypes";

// Default state interface.
interface DefaultStateI {
  loading: string,
  item?: ItemType,
  itemSearchData?: ItemTypeSearchData,
  refresh?: boolean,
  itemFilters?: object,
  itemList?: ItemList,
  itemListSearchData?: ItemListSearchData,
  itemLabelList?: ItemLabelsType[],
  itemLabelByTypeList?: ItemLabelsByCategoryType[],
  actionStatus?: any,
  processedLabel?: ItemLabelResponse,
  itemsSearchNames?: ItemSearchNamesResponse,
  processedLabelValue?: ItemLabelValueResponse,
  itemDetail?: ItemDatailResponse,
  itemParticipationList?: ItemParticipationList
}

// Default state.
const defaultState: DefaultStateI = {
  loading: '',
};

const itemReducer = (state: DefaultStateI = defaultState, action: ItemDispatchTypes) : DefaultStateI => {
  switch (action.type) {
    case ITEM_FAIL:
      return {
        ...state,
        loading: '',
        actionStatus: {menssage: action.payload, status: 'error'}
      }
    case ITEM_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case ADD_ITEM:
      return {
        ...state,
        loading: '',
        actionStatus: {menssage: action.payload, status: 'success'},
        item: action.item
      }
    case DELETE_ITEM:
      return {
        ...state,
        loading: '',
        actionStatus: {menssage: action.payload, status: 'success'}
      }
    case ITEM_PUT:
      return {
        ...state,
        loading: '',
        item: action.payload.item,
        actionStatus: {menssage: action.payload.message, status: 'success'}
      }
    case ITEM_LIST:
      return {
        ...state,
        loading: '',
        itemList: action.payload
      }
    case ITEM_PARTICIPATION_LIST:
      return {
        ...state,
        loading: '',
        itemParticipationList:action.payload
      }
    case ITEM_SEARCH_NAMES:
      return {
        ...state,
        loading: '',
        itemsSearchNames: action.payload
      }
    case ITEM_DETAIL:
      return {
        ...state,
        loading: '',
        itemDetail: action.payload
      }
    case ITEM_LIST_SEARCH_DATA:
      return {
        ...state,
        loading: '',
        itemListSearchData: action.payload
      }  
    case ITEM_LABEL_LIST:
      return {
        ...state,
        loading: '',
        itemLabelList: action.payload
      }
    case ITEM_LABEL_BY_TYPE_LIST:
      return {
        ...state,
        loading: '',
        itemLabelByTypeList: action.payload
      }
    case ITEM_REFRESH:
      return {
        ...state,
        refresh: action.payload
      }
    case ITEM_SET_FILTER:
      return {
        ...state,
        itemFilters: action.payload
      }
    case ADD_ITEM_DISCOUNT:
      return {
        ...state,
        loading: '',
        actionStatus: {menssage: action.payload, status: 'success'},
      }
    case UPDATE_ITEM_DISCOUNT:
      return {
        ...state,
        loading: '',
        actionStatus: {menssage: action.payload, status: 'success'},
      }
    case ADD_ITEM_LABEL:
      return {
        ...state,
        loading: '',
        processedLabel: action.payload
      }
    case UPDATE_ITEM_LABEL:
      return {
        ...state,
        loading: '',
        processedLabel: action.payload
      }
    case ADD_ITEM_LABEL_VALUE:
      return {
        ...state,
        loading: '',
        processedLabelValue: action.payload
      }
    case UPDATE_ITEM_LABEL_VALUE:
      return {
        ...state,
        loading: '',
        processedLabelValue: action.payload
      }
    case ITEM_LABEL_SAVED:
      return {
        ...state,
        loading: '',
        actionStatus: {menssage: action.payload, status: 'success'},
      }
    case ITEM_PRICE_QUANTITY_UPDATE_STATUS:
      return {
        ...state,
        loading: '',
        actionStatus: {menssage: `status.${action.payload}-update-price-quantity`, status: action.payload}
      }
    case ITEM_AUTOMATIC_PRICE_PUT:
      return {
        ...state,
        loading: '',
        actionStatus: { menssage: action.payload, status: 'success' },
      }
    case ITEM_SINGLE_PRICE_PUT:
      return {
        ...state,
        loading: '',
        actionStatus: { menssage: action.payload, status: 'success' },
      }
    case ITEM_INDIVIDUAL_PUT:
      return {
        ...state,
        loading: '',
        actionStatus: { menssage: action.payload, status: 'success' },
      }
    case ITEM_SINGLE_DESC_PUT:
      return {
        ...state,
        loading: '',
        actionStatus: { menssage: action.payload, status: 'success' },
      }
    default:
      return state
  }
};


export default itemReducer;
