// React.
import React, { useState, useEffect } from 'react';

// Material UI
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { IconButton } from '@material-ui/core';
import DeleteIcon from "@material-ui/icons/Delete";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../Store";
import {getParticipationItems } from "../../../actions/itemActions/ItemActions";

// Translations.
import { useTranslation } from 'react-i18next';

// Styles.
import useStyles from './OrderBoosterSearchPrizeStyle';

// Model types.
import { ItemParticipationType } from '../models/ModelTypes';
import { getVendors } from '../../../actions/vendorActions/VendorActions';
import { getEventById, updateEventInformation } from '../../../actions/eventActions/eventActions';


const OrderBoosterSearchPrize = () => {
  const classes = useStyles();
  const [t] = useTranslation('global');
  const dispatch = useDispatch();

   // Redux state.
   const itemState = useSelector((state: RootStore) => state.item);
   const itemParticipationList = itemState.itemParticipationList; 
   const eventState = useSelector((state: RootStore) => state.events);

  // Local component state.
  const [currentItem, setCurrentItem] = useState<ItemParticipationType | undefined>(undefined);
  const [currentParticipationItem, setCurrentParticipationItem] = useState<any>(eventState.eventDetail?.itemName);
  const [typingValue, setTypingValue] = useState<string>('');
  const [searchOptions, setSearchOptions] = useState<Array<any>>([]);

 

  useEffect(() => {
    dispatch(getVendors());
    // eslint-disable-next-line
  }, []);

  useEffect(()=>{
    dispatch(getEventById(`${eventState.eventDetail?.id}`))
    // eslint-disable-next-line
  },[eventState.event])

  useEffect(()=>{
    setCurrentParticipationItem(eventState.eventDetail?.itemName)
        // eslint-disable-next-line
  },[eventState.eventDetail?.itemName])

  useEffect(() => {
    if (typingValue !== '') {
      const delayDebounceFn = setTimeout(
        () => triggerSearchItemsAutocomplete(),
        500
      );
      return () => clearTimeout(delayDebounceFn)
    }
    // eslint-disable-next-line
  }, [typingValue]);

  // Update the search options array.
  useEffect(() => {
    if (itemParticipationList !== undefined) {
      var optionsList: Array<any> = [];
     
      // Add items results.
      itemParticipationList?.items.forEach((option: any) => {
        optionsList.push({ element: option, type: 'item' });
      });
      setSearchOptions(optionsList);
    }
    // eslint-disable-next-line
  }, [itemParticipationList]);
  const triggerSearchItemsAutocomplete = () => {
    const qParamsItems = { eventId: eventState.eventDetail?.id, itemName:typingValue};
    // Search normal items.
    dispatch(getParticipationItems(qParamsItems, 'seller'));
  }
  const handleChangeItemName = (event:any,selectedItem: any) => {
     setCurrentItem(selectedItem.element);
  };
  const handleChangeTyping = (event: React.ChangeEvent<{ value: string }>) => {
    setTypingValue(event.target.value);
  };
  const numberFormat = (value: number) => {
    var roundedNumber = Math.round(value);
    return roundedNumber.toLocaleString('en-US')
  }
  const getVendorOptionLabel: any = (option: any): string => {
    if (option.type !== undefined) {
      if (option.type === 'item') {
        const price = option.element.price ? `${t('items.normal-price')}: ` + numberFormat(option.element.price) : '';
        let priceString = `${price}`;
        return `${option.element.itemName} | Stock: ${option.element.totalQuantity} | ${priceString}`;
      } else {
        return '';
      }
    } else {
      return '';
    }
  }
  const addItemOrder = () => {
      if (currentItem !== undefined && eventState.eventDetail?.id!==undefined) {
        let payload= {
         itemId:currentItem.itemId,
         havePrizeParticipation: true 
        }
        dispatch(updateEventInformation(payload,`${eventState.eventDetail?.id}`))
      } 
  };
  function deleteAddedItem() {
    let payload= {
          havePrizeParticipation: false 
         }
    dispatch(updateEventInformation(payload,`${eventState.eventDetail?.id}`))
  }

  return (
    <div className={classes.root}>
        <div className={classes.boxTop}>
          <ConfirmationNumberIcon color="primary" fontSize="medium" />
          <Typography color="primary">
            {t('events.add-participation')}
          </Typography>        
        </div>
        <div className={classes.boxBottom}>
          <form className={classes.boxForm}>
            <Grid container spacing={3}>
              <Grid item xs={10} sm={10}>
              <Autocomplete
              freeSolo
              disableClearable
              id="item-name-autocomplete"
              value={''}
              onChange={handleChangeItemName}
              options={searchOptions}
              getOptionLabel={getVendorOptionLabel}
              renderInput={(params) => (
                <Typography noWrap>
                  <TextField
                    {...params}
                    id="search-name"
                    name="search-name"
                    label={<span style={{ display: 'block', overflow: 'hidden', textOverflow: 'ellipsis' }}>Nombre del articulo</span>}
                    value={typingValue !== '' ? typingValue : ''}
                    onChange={handleChangeTyping}
                    className={classes.fieldClass}
                    InputProps={{ ...params.InputProps, type: 'search' }}
                  />
                </Typography>)}
                  />
              </Grid>
            </Grid>
          </form>
          <div className={classes.buttons}>
          <Button onClick={addItemOrder} variant="contained" color="primary" className={classes.buttonsLast}>
            Guardar      
          </Button>
          </div>
          </div>  
          {eventState.eventDetail?.havePrizeParticipation?
          <div className={classes.itemInfo}>
          <div className={classes.header}>
              <div style={{width:'100px', textAlign:'center'}}>{t('items.quantity')}</div>
              <div style={{width:'400px'}}>{t('items.name')}</div>
            </div>
        <div className={classes.prizeItem}>
          <p style={{width:'100px', textAlign:'center'}}>{eventState.eventDetail?.totalPlayers}</p>
          <p style={{width:'400px', textAlign:'center'}}>{currentParticipationItem}</p>
          <IconButton
            aria-label="delete"
            onClick={deleteAddedItem}
          >
           <DeleteIcon color="action" />
          </IconButton>
        </div>
       </div>:''   
        }  
        </div>
  )
}

export default OrderBoosterSearchPrize;