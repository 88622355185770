import {Dispatch} from "redux";
import axios from 'axios';
import {
  ADD_ITEM, ITEM_FAIL, ITEM_LOADING, ITEM_LIST, ITEM_LIST_SEARCH_DATA, ITEM_LABEL_LIST, DELETE_ITEM, ITEM_LABEL_BY_TYPE_LIST, 
  ITEM_PUT, ITEM_REFRESH, ITEM_SET_FILTER, ADD_ITEM_DISCOUNT, UPDATE_ITEM_DISCOUNT, ITEM_SEARCH_NAMES, ITEM_DETAIL,
  ADD_ITEM_LABEL, UPDATE_ITEM_LABEL, ADD_ITEM_LABEL_VALUE, UPDATE_ITEM_LABEL_VALUE, ITEM_LABEL_SAVED, ITEM_PRICE_QUANTITY_UPDATE_STATUS, 
  ITEM_AUTOMATIC_PRICE_PUT, ITEM_SINGLE_PRICE_PUT, ITEM_INDIVIDUAL_PUT, ITEM_SINGLE_DESC_PUT, IndividualItemObj, ItemDispatchTypes, ITEM_PARTICIPATION_LIST
} from "./ItemActionsTypes";
import { ItemQuantity } from "../../components/admin/models/ModelTypes";

//  JWT decode token.
import jwtDecode from 'jwt-decode';
import { getProjectConfig } from "../../getProjectConfig";

const apiURL: string | undefined = process.env.REACT_APP_API_URL;
const token: string | undefined = localStorage.token;

/**
 * Update the refresh view state, when the value is true the view will refresh.
 * @param {boolean} value
 */
export const refreshItemView = (value: boolean) => async (dispatch: Dispatch<ItemDispatchTypes>) => {
  dispatch({
    type: ITEM_REFRESH,
    payload: value
  })
};

/**
 * Update the view filters state.
 * @param {object} filters
 */
export const itemSetFilters = (filters: object) => async (dispatch: Dispatch<ItemDispatchTypes>) => {
  dispatch({
    type: ITEM_SET_FILTER,
    payload: filters
  })
};

/**
 * Clears the items state list.
 */
export const clearItemsList = () => async (dispatch: Dispatch<ItemDispatchTypes>) => {
  if (apiURL !== '') {
    try {
      dispatch({
        type: ITEM_LOADING,
        payload: 'CLEARING'
      })

      const data: any = {
        first: true,
        last: true,
        number: 0,
        size: 0,
        totalPages: 0,
        items: [],
        labels: []
      };

      dispatch({
        type: ITEM_LIST,
        payload: data
      })

    } catch (e) {
      dispatch({
        type: ITEM_FAIL,
        payload: 'status.get-error'
      })
    }
  } else {
    dispatch({
      type: ITEM_FAIL,
      payload: 'status.get-error'
    })
  }
};

/**
 * Get all items from the BE, it allow filtering.
 * @param {object|null} filters
 * @param {string} role
 */
export const getItems = (qParams: object|null, role: string) => async (dispatch: Dispatch<ItemDispatchTypes>) => {
  if (apiURL !== '') {
    try {
      dispatch({
        type: ITEM_LOADING,
        payload: 'LIST'
      })

      const headers: object = {
        ContentType: 'application/json',
        Authorization: 'Bearer ' + token
      }

      const requestPath = `${getBasePath(['guest', role])}/getAllItems`;

      const res = await axios.get(requestPath, {
        params: qParams,
        headers: headers
       });

       if (res.status === 200) {
         const queryData = (res.data.data.itemPage !== undefined) ? res.data.data.itemPage: res.data.data;
          const items:any = [];

        for(var i = 0; i < queryData.content.length; i++) {
          const item = queryData.content[i];
        
          items.push({
            id: item.id,
            name: item.name,
            price: item.price,
            commission: item.commission,
            state: item.state,
            code: item.code,
            weightPerUnit: item.weightPerUnit,
            minimumQuantity: item.minimumQuantity,
            costPrice: item.costPrice,
            customPrices: item.customPrices,
            notes: item.notes,
            additionalInfo: item.additionalInfo,
            isDeleted: item.isDeleted,
            isFeature: item.isFeature,
            itemType: item.itemType,
            currency: item.currency,
            vendor: item.vendor,
            warehousesQuantities: item.warehousesQuantities,
            warehousesCustomQuantities: item.warehousesCustomQuantities,
            labelValues: item.labelValues,
            images: item.images,
            discounts: item.discount,
            priceWithTaxes: item.priceWithTaxes,
            creationDate: item.creationDate,
            lastUpdate: item.lastUpdate,
            isPublished: item.isPublished,
            category: item.category,
            totalQuantity: item.totalQuantity,
            automaticPrice: item.automaticPrice,
            additionalPrice: item.additionalPrice
          });
          
        }

        const data:any = {
          first: queryData.first,
          last: queryData.last,
          number: queryData.number,
          size: queryData.size,
          totalPages: queryData.totalPages,
          items: items,
          ...(res.data.data.labels !== undefined) && { labels: res.data.data.labels }
        };

        dispatch({
          type: ITEM_LIST,
          payload: data
        })
       } else {
        dispatch({
          type: ITEM_FAIL,
          payload: 'status.get-error'
        })
       }
    } catch(e) {
      dispatch({
        type: ITEM_FAIL,
        payload: 'status.get-error'
      })
    }
  } else {
    dispatch({
      type: ITEM_FAIL,
      payload: 'status.get-error'
    })
  }
};


//Get Participation Items

export const getParticipationItems = (qParams:any, role: string) => async (dispatch: Dispatch<ItemDispatchTypes>) => {
  if (apiURL !== '') {
    try {
      dispatch({
        type: ITEM_LOADING,
        payload: 'LIST'
      })

      const headers: object = {
        ContentType: 'application/json',
        Authorization: 'Bearer ' + token
      }

      const requestPath = `${getBasePath(['guest', role])}/getItemsOrderForParticipation/${qParams.eventId}`;

      const res = await axios.get(requestPath, {
        params: qParams,
        headers: headers
       });


       if (res.status === 200) {
         const queryData = res.data.data;
          const items:any = [];

        for(var i = 0; i < queryData.length; i++) {
          const item = queryData[i];
          items.push(item);
        }

        const data:any = {
          items
        };


        dispatch({
          type: ITEM_PARTICIPATION_LIST,
          payload: data
        })
       } else {
        dispatch({
          type: ITEM_FAIL,
          payload: 'status.get-error'
        })
       }
    } catch(e) {
      dispatch({
        type: ITEM_FAIL,
        payload: 'status.get-error'
      })
    }
  } else {
    dispatch({
      type: ITEM_FAIL,
      payload: 'status.get-error'
    })
  }
};

/**
 * Get the item datail by code.
 * @param {string} itemCode
 */
export const getItemByCode = (itemCode: string) => async (dispatch: Dispatch<ItemDispatchTypes>) => {
  if (apiURL !== '') {
    try {
      dispatch({
        type: ITEM_LOADING,
        payload: 'ITEM_DETAIL'
      })

      const headers: object = {
        ContentType: 'application/json',
        Authorization: 'Bearer ' + token
      }

      const requestPath = `${apiURL}/guest/getItemDetail/${itemCode}`;

      const res = await axios.get(requestPath, {
        headers: headers
      });

      if (res.status === 200) {
        const queryData = res.data.data;

        dispatch({
          type: ITEM_DETAIL,
          payload: queryData
        })
      } else {
        dispatch({
          type: ITEM_FAIL,
          payload: 'status.get-error'
        })
      }
    } catch (e) {
      dispatch({
        type: ITEM_FAIL,
        payload: 'status.get-error'
      })
    }
  } else {
    dispatch({
      type: ITEM_FAIL,
      payload: 'status.get-error'
    })
  }
};

/**
 * Get all items from the BE getItemSearchData, it allow filtering.
 * @param {object|null} filters
 */
 export const getItemSearchData = (qParams: object|null) => async (dispatch: Dispatch<ItemDispatchTypes>) => {
  if (apiURL !== '') {
    try {
      dispatch({
        type: ITEM_LOADING,
        payload: 'LIST'
      })

      const headers: object = {
        ContentType: 'application/json',
        Authorization: 'Bearer ' + token
      }

      const requestPath = `${apiURL}/guest/getItemSearchData`;

      const res = await axios.get(requestPath, {
        params: qParams,
        headers: headers
       });

       if (res.status === 200) {
        const queryData = (res.data.data.itemPage !== undefined) ? res.data.data.itemPage: res.data.data;
        const items:any = [];  

        for(var i = 0; i < queryData.content.length; i++) {
          const item = queryData.content[i];

          // Getting vendors data.
          const vendors: any = [];
          for(let x = 0; x < item.itemSearchVendorData.length; x++) {
            const vendor = item.itemSearchVendorData[x];

            // Getting quantity data.
            const quantityData: any = [];
            for(let j = 0; j < vendor.itemQuantityData.length; j++) {
              const quantity = vendor.itemQuantityData[j];

              quantityData.push({
                customReference: quantity.customReference,
                discountFinalPrice: quantity.discountFinalPrice,
                quantity: quantity.quantity,
                finalPrice: quantity.finalPrice,
                typeOfWarehouse: quantity.typeOfWarehouse
              });
            }

            vendors.push({
              id: vendor.id,
              itemId: vendor.itemId,
              name: vendor.name,
              totalQuantity: vendor.totalQuantity,
              itemQuantityData: quantityData,
            });
          }
          // Creating the item data.
          items.push({
            additionalInfo: item.additionalInfo,
            category: item.category,
            code: item.code,
            feature: item.feature,
            imageURL: item.imageURL,
            name: item.name,
            notes: item.notes,
            itemSearchVendorData: vendors,
            setName: item.setName,
          });          
        }

        const data:any = {
          first: queryData.first,
          last: queryData.last,
          number: queryData.number,
          size: queryData.size,
          totalPages: queryData.totalPages,
          items: items,
          ...(res.data.data.labels !== undefined) && { labels: res.data.data.labels },
          dolarValue: res.data.data.dolarValue
        };

        dispatch({
          type: ITEM_LIST_SEARCH_DATA,
          payload: data
        });
       } else {
        dispatch({
          type: ITEM_FAIL,
          payload: 'status.get-error'
        })
       }
    } catch(e) {
      dispatch({
        type: ITEM_FAIL,
        payload: 'status.get-error'
      })
    }
  } else {
    dispatch({
      type: ITEM_FAIL,
      payload: 'status.get-error'
    })
  }
};

/**
 * Get a list of items names which match the search word, it allow filtering.
 * @param {object|null} filters
 */
export const getItemSearchNames = (qParams: object | null) => async (dispatch: Dispatch<ItemDispatchTypes>) => {
  if (apiURL !== '') {
    try {
      dispatch({
        type: ITEM_LOADING,
        payload: 'ITEM_NAMES'
      })

      const headers: object = {
        ContentType: 'application/json',
        Authorization: 'Bearer ' + token
      }

      const requestPath = `${apiURL}/guest/getItemSearchNames`;

      const res = await axios.get(requestPath, {
        params: qParams,
        headers: headers
      });

      if (res.status === 200) {

        dispatch({
          type: ITEM_SEARCH_NAMES,
          payload: res.data.data
        })
      } else {
        dispatch({
          type: ITEM_FAIL,
          payload: 'status.get-error'
        })
      }
    } catch (e) {
      dispatch({
        type: ITEM_FAIL,
        payload: 'status.get-error'
      })
    }
  } else {
    dispatch({
      type: ITEM_FAIL,
      payload: 'status.get-error'
    })
  }
};

/**
 * Save a item on the BE.
 * @param {any} item
 */
export const addItem = (item: any) => async (dispatch: Dispatch<ItemDispatchTypes>) => {
  if (apiURL !== '' && token !== '') {
    try {
      dispatch({
        type: ITEM_LOADING,
        payload: 'ADD_ITEM'
      })

      var bodyFormData = new FormData();
      item.name && bodyFormData.append('name', item.name);
      item.code && bodyFormData.append('code', item.code);
      item.commission && bodyFormData.append('commission', item.commission);
      item.costPrice && bodyFormData.append('costPrice', item.costPrice);
      item.minimumQuantity && bodyFormData.append('minimumQuantity', item.minimumQuantity);
      item.notes && bodyFormData.append('notes', item.notes);
      item.price && bodyFormData.append('price', item.price);
      item.weightPerUnit && bodyFormData.append('weightPerUnit', item.weightPerUnit);
      (item.quantity || item.quantity === 0) && bodyFormData.append('quantity', item.quantity);
      item.itemTypeId && bodyFormData.append('itemTypeId', item.itemTypeId);
      item.labelValuesIds && bodyFormData.append('labelValuesIds', item.labelValuesIds);

      bodyFormData.append('vendorId', String(getProjectConfig().VENDOR_ID));
  
      // Add the image files.
      if (item.files) {
        for (var i = 0; i < item.files.length; i++) {
          bodyFormData.append('files', item.files[i].file, String(i));
        } 
      }

      // Add quantities per vendor
      if (item.quantities) {
        item.quantities.forEach( (quantity:ItemQuantity,index:number) => {
          bodyFormData.append(`quantities[${index}].quantity`, quantity.quantity)
          bodyFormData.append(`quantities[${index}].vendorId`, quantity.vendorId)
        })
      }

      const configPUT = {
        headers: {
          'Accept': '*/*',
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'multipart/form-data',
        }
      };
    
      const addRes = await axios.post(`${getBasePath(['admin'])}/registerItem`, bodyFormData, configPUT);

      if (addRes.status === 200) {
        dispatch({
          type: ADD_ITEM,
          payload: 'status.success-create',
          item: addRes.data.data
        })
      }
       
    } catch(e) {
      dispatch({
        type: ITEM_FAIL,
        payload: 'status.create-error'
      })
    }
  } else {
    dispatch({
      type: ITEM_FAIL,
      payload: 'status.create-error'
    })
  }
};

/**
 * Update a item on the BE.
 * @param {any} item
 */
export const updateItem = (item: any) => async (dispatch: Dispatch<ItemDispatchTypes>) => {
  try {
    dispatch({
      type: ITEM_LOADING,
      payload: 'ITEM_UPDATE'
    })

    var bodyFormData = new FormData();
    bodyFormData.append('id', item.id);
    item.name && bodyFormData.append('name', item.name);
    item.code && bodyFormData.append('code', item.code);
    item.brand && bodyFormData.append('brand', item.brand);
    item.commission && bodyFormData.append('commission', item.commission);
    item.costPrice && bodyFormData.append('costPrice', item.costPrice);
    item.minimumQuantity && bodyFormData.append('minimumQuantity', item.minimumQuantity);
    item.notes && bodyFormData.append('notes', item.notes);
    item.imagesIdToRemove && bodyFormData.append('imagesIdToRemove', item.imagesIdToRemove);
    item.labelValuesIdsToAdd && bodyFormData.append('labelValuesIdsToAdd', item.labelValuesIdsToAdd);
    item.labelValuesIdsToRemove && bodyFormData.append('labelValuesIdsToRemove', item.labelValuesIdsToRemove);
    item.price && bodyFormData.append('price', item.price);
    item.weightPerUnit && bodyFormData.append('weightPerUnit', item.weightPerUnit);
    item.isForSale && bodyFormData.append('isForSale',item.isForSale);
    (item.isFeature !== undefined) && bodyFormData.append('isFeature', item.isFeature);
    bodyFormData.append('isPublished', item.isPublished);

    // Add the image files.
    if (item.files) {
      for (var i = 0; i < item.files.length; i++) {
        bodyFormData.append('files', item.files[i].file, String(i));
      }
    }
  
    const configPUT = {
      headers: {
        'Accept': '*/*',
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'multipart/form-data',
      }
    };
  
    const res = await axios.put(`${ getBasePath(['admin']) }/updateItem`, bodyFormData, configPUT);
    if (res.status === 200) {
      dispatch({
        type: ITEM_PUT,
        payload: {message: 'status.success-update', item: res.data.data[0]}
      })
    } else {
      dispatch({
        type: ITEM_FAIL,
        payload: 'status.error-update'
      })
    }

  } catch(e) {
    dispatch({
      type: ITEM_FAIL,
      payload: 'status.error-update'
    })
  }
};


/**
 * Update a item on the BE.
 * @param {string} published
 * @param {string} itemCode 
 */
 export const updateItemPublished = (published: string, itemCode: string) => async (dispatch: Dispatch<ItemDispatchTypes>) => {
  try {
    dispatch({
      type: ITEM_LOADING,
      payload: 'ITEM_UPDATE'
    })

    var bodyFormData = new FormData();
    bodyFormData.append('code', itemCode);
    bodyFormData.append('isPublished', published);
  
    const configPUT = {
      headers: {
        'Accept': '*/*',
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'multipart/form-data',
      }
    };
  
    const res = await axios.put(`${ getBasePath(['admin']) }/updateItem`, bodyFormData, configPUT);
    if (res.status === 200) {
      dispatch({
        type: ITEM_PUT,
        payload: {message: 'status.success-update', item: res.data.data[0]}
      })
    } else {
      dispatch({
        type: ITEM_FAIL,
        payload: 'status.error-update'
      })
    }

  } catch(e) {
    dispatch({
      type: ITEM_FAIL,
      payload: 'status.error-update'
    })
  }
};

export const updateIsForSale = (isForSale: string, itemCode: string) => async (dispatch: Dispatch<ItemDispatchTypes>) => {
  try {
    dispatch({
      type: ITEM_LOADING,
      payload: 'ITEM_UPDATE'
    })

    var bodyFormData = new FormData();
    bodyFormData.append('code', itemCode);
    bodyFormData.append('isForSale', isForSale);
  
    const configPUT = {
      headers: {
        'Accept': '*/*',
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'multipart/form-data',
      }
    };
  
    const res = await axios.put(`${ getBasePath(['admin']) }/updateItem`, bodyFormData, configPUT);
    if (res.status === 200) {
      dispatch({
        type: ITEM_PUT,
        payload: {message: 'status.success-update', item: res.data.data[0]}
      })
    } else {
      dispatch({
        type: ITEM_FAIL,
        payload: 'status.error-update'
      })
    }

  } catch(e) {
    dispatch({
      type: ITEM_FAIL,
      payload: 'status.error-update'
    })
  }
};

/**
 * Update a item on the BE.
 * @param {number} price
 * @param {number} additionalPrice
 * @param {number} itemId 
 */
export const updateItemSinglePrice = (price: number, additionalPrice: number, itemId: number) => async (dispatch: Dispatch<ItemDispatchTypes>) => {
  try {
    dispatch({
      type: ITEM_LOADING,
      payload: 'ITEM_PRICE_UPDATE'
    })

    var bodyFormData = {
      additionalPrice: additionalPrice,
      id: itemId,
      price: price
    };

    const configPUT = {
      headers: {
        'Accept': '*/*',
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
      }
    };

    const res = await axios.put(`${getBasePath(['seller'])}/updateSinglePrice`, bodyFormData, configPUT);
    if (res.status === 200) {
      dispatch({
        type: ITEM_SINGLE_PRICE_PUT,
        payload: 'status.success-single-price-update'
      })
    } else {
      dispatch({
        type: ITEM_FAIL,
        payload: 'status.error-update'
      })
    }

  } catch (e) {
    dispatch({
      type: ITEM_FAIL,
      payload: 'status.error-update'
    })
  }
};

/**
 * Update individual item.
 * @param {IndividualItemObj} individualItem
 */
export const updateIndividualItemValues = (individualItem: IndividualItemObj) => async (dispatch: Dispatch<ItemDispatchTypes>) => {
  try {
    dispatch({
      type: ITEM_LOADING,
      payload: 'ITEM_UPDATE'
    })

    var bodyFormData = { ...individualItem };

    const configPUT = {
      headers: {
        'Accept': '*/*',
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
      }
    };

    const res = await axios.put(`${getBasePath(['admin'])}/updateIndividualItem`, bodyFormData, configPUT);
    if (res.status === 200) {
      dispatch({
        type: ITEM_INDIVIDUAL_PUT,
        payload: 'status.success-individual-update'
      })
    } else {
      dispatch({
        type: ITEM_FAIL,
        payload: 'status.error-update'
      })
    }

  } catch (e) {
    dispatch({
      type: ITEM_FAIL,
      payload: 'status.error-update'
    })
  }
};

/**
 * Update a item on the BE.
 * @param {boolean} isAutomatic
 * @param {number} itemId 
 */
export const updateItemAutomaticPrice = (isAutomatic: boolean, itemId: number) => async (dispatch: Dispatch<ItemDispatchTypes>) => {
  try {
    dispatch({
      type: ITEM_LOADING,
      payload: 'ITEM_PRICE_UPDATE'
    })

    var bodyFormData = {
      automaticPrice: isAutomatic,
      id: itemId
    };

    const configPUT = {
      headers: {
        'Accept': '*/*',
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
      }
    };

    const res = await axios.put(`${getBasePath(['seller'])}/updateAutomaticPrice`, bodyFormData, configPUT);
    if (res.status === 200) {
      dispatch({
        type: ITEM_AUTOMATIC_PRICE_PUT,
        payload: 'status.success-automatic-price-update'
      })
    } else {
      dispatch({
        type: ITEM_FAIL,
        payload: 'status.error-update'
      })
    }

  } catch (e) {
    dispatch({
      type: ITEM_FAIL,
      payload: 'status.error-update'
    })
  }
};

/**
 * Update the quantity notification state.
 * @param {string} resultStatus
 */
 export const notifyPriceQuantityUpdateStatus = (resultStatus:string) => async (dispatch: Dispatch<ItemDispatchTypes>) => {
  dispatch({
    type: ITEM_PRICE_QUANTITY_UPDATE_STATUS,
    payload: resultStatus
  })
};


/**
 * Gets a list of labels by category id from BE.
 * @param {number} categoryId
 */
export const getItemLabels = (categoryId: number, pageNumber?:number, size?:number) => async (dispatch: Dispatch<ItemDispatchTypes>) => {


  if (categoryId === 0) {
    dispatch({
      type: ITEM_LABEL_LIST,
      payload: []
    })
  } else {
    if (apiURL !== '' && token !== '') {
      try {
        dispatch({
          type: ITEM_LOADING,
          payload: 'ITEM_GET_LABELS'
        })

        const headers: object = {
          ContentType: 'application/json',
          Authorization: 'Bearer ' + token
        }

        const params = {
          page: pageNumber,
          size: size
        };

        const config = {
          params: params,
          headers: headers
        };


        const requestPath = `${ getBasePath(['guest', 'user']) }/getAllLabel/${categoryId}`;

        const res = await axios.get(requestPath,config);
  
         if (res.status === 200) {
            const queryData = res.data.data.content;
            const labels:any = [];
  
          for(var i = 0; i < queryData.length; i++) {
            const label = queryData[i];
            const labelValues:any = [];

            const requestPathValues = `${ getBasePath(['guest', 'user']) }/getAllLabelValues/${label.id}`;
            
            // Get label values.
            const resValues = await axios.get(requestPathValues, {
              headers: headers
             });
      
             if (resValues.status === 200) {
              const queryData = resValues.data.data.content;
              for(var j = 0; j < queryData.length; j++) {
                const value = queryData[j];
              
                labelValues.push({
                  id: value.id,
                  value: value.value,
                });
              }
             }
            labels.push({
              id: label.id,
              value: label.name,
              description: label.description,
              labelValues: labelValues,
              totalPages:res.data.data.totalPages
            });
          }
  
          dispatch({
            type: ITEM_LABEL_LIST,
            payload: labels
          })
         } else {
          dispatch({
            type: ITEM_FAIL,
            payload: 'status.get-error'
          })
         }
      } catch(e) {
        dispatch({
          type: ITEM_FAIL,
          payload: 'status.get-error'
        })
      }
    } else {
      dispatch({
        type: ITEM_FAIL,
        payload: 'status.get-error'
      })
    }
  }
};

/**
 * Gets a list of labels by many category ids from BE.
 * @param {Array<string>} typeIds
 */
export const getItemLabelsByManyTypes = (typeIds: Array<string>) => async (dispatch: Dispatch<ItemDispatchTypes>) => {

  if (typeIds.length === 0) {
    dispatch({
      type: ITEM_LABEL_LIST,
      payload: []
    })
  } else {
    if (apiURL !== '' && token !== '') {
      try {
        dispatch({
          type: ITEM_LOADING,
          payload: 'ITEM_GET_LABELS'
        })

        const headers: object = {
          ContentType: 'application/json',
          Authorization: 'Bearer ' + token
        }
        const result: any = [];
        for (var t = 0; t < typeIds.length; t++) {
          const tId = typeIds[t];
          const requestPath = `${getBasePath(['guest', 'user'])}/getAllLabel/${tId}`;

          const res = await axios.get(requestPath, {
            headers: headers
          });

          if (res.status === 200) {
            var labels: any = [];
            const queryData = res.data.data.content;
            for (var i = 0; i < queryData.length; i++) {
              const label = queryData[i];
              const labelValues: any = [];

              const requestPathValues = `${getBasePath(['guest', 'user'])}/getAllLabelValues/${label.id}`;

              // Get label values.
              const resValues = await axios.get(requestPathValues, {
                headers: headers
              });

              if (resValues.status === 200) {
                const queryData = resValues.data.data.content;
                for (var j = 0; j < queryData.length; j++) {
                  const value = queryData[j];
                  labelValues.push({
                    id: value.id,
                    value: value.value
                  });
                }
              }

              labels.push({
                id: label.id,
                value: label.name,
                description: label.description,
                labelValues: labelValues
              });
            }
          }
          result.push({
            typeId: tId,
            labels: labels
          });
        }
        dispatch({
          type: ITEM_LABEL_BY_TYPE_LIST,
          payload: result
        })
      } catch (e) {
        dispatch({
          type: ITEM_FAIL,
          payload: 'status.get-error'
        })
      }
    } else {
      dispatch({
        type: ITEM_FAIL,
        payload: 'status.get-error'
      })
    }
  }
};

/**
 * Save a item label on the BE.
 * @param {number} itemTypeId
 * @param {string} name
 * @param {string} description
 * @param {number} index
 */
 export const registerLabel = (itemTypeId: number, name: string, description: string, index:number) => async (dispatch: Dispatch<ItemDispatchTypes>) => {
  try {
    dispatch({
      type: ITEM_LOADING,
      payload: 'ITEM_LABEL'
    })
   
    const bodyForm = {
      description: description,
      itemTypeId: itemTypeId,
      name: name
    };
  
    const configPOST = {
      headers: {
        'Accept': '*/*',
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
      }
    };
    const res = await axios.post(`${ getBasePath(['admin']) }/registerLabel`, bodyForm, configPOST);
    if (res.status === 200) {
      dispatch({
        type: ADD_ITEM_LABEL,
        payload: {label: res.data.data, message: 'status.success-create', index}
      })
    } else {
      dispatch({
        type: ITEM_FAIL,
        payload: 'status.error-create'
      })
    }

  } catch(e) {
    dispatch({
      type: ITEM_FAIL,
      payload: 'status.error-create'
    })
  }
};

/**
 * Save a item label on the BE.
 * @param {number} id
 * @param {string} name
 * @param {string} description 
 * @param {number} index
 */
 export const updateLabel = (id: number, name: string, description: string, index:number) => async (dispatch: Dispatch<ItemDispatchTypes>) => {
  try {
    dispatch({
      type: ITEM_LOADING,
      payload: 'ITEM_LABEL'
    })
   
    const bodyForm = {
      description: description,
      id: id,
      name: name
    };
  
    const configPOST = {
      headers: {
        'Accept': '*/*',
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
      }
    };
    const res = await axios.put(`${ getBasePath(['admin']) }/updateLabel`, bodyForm, configPOST);
    if (res.status === 200) {
      dispatch({
        type: UPDATE_ITEM_LABEL,
        payload: {label: res.data.data, message: 'status.success-update', index}
      })
    } else {
      dispatch({
        type: ITEM_FAIL,
        payload: 'status.error-update'
      })
    }

  } catch(e) {
    dispatch({
      type: ITEM_FAIL,
      payload: 'status.error-update'
    })
  }
};


/**
 * Save a item label value on the BE.
 * @param {number} labelId
 * @param {string} value
 */
 export const registerLabelValues = (labelId: number, value: string) => async (dispatch: Dispatch<ItemDispatchTypes>) => {
  try {
    dispatch({
      type: ITEM_LOADING,
      payload: 'ITEM_LABEL_VALUE'
    })
   
    const bodyForm = {
      labelId: labelId,
      value: value
    };
  
    const configPOST = {
      headers: {
        'Accept': '*/*',
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
      }
    };
    const res = await axios.post(`${ getBasePath(['admin']) }/registerLabelValues`, bodyForm, configPOST);
    if (res.status === 200) {
      dispatch({
        type: ADD_ITEM_LABEL_VALUE,
        payload: {labelValue: res.data.data, message: 'status.success-create'}
      })
    } else {
      dispatch({
        type: ITEM_FAIL,
        payload: 'status.error-create'
      })
    }

  } catch(e) {
    dispatch({
      type: ITEM_FAIL,
      payload: 'status.error-create'
    })
  }
};

/**
 * Save a item label value on the BE.
 * @param {number} id
 * @param {string} value
 */
 export const updateLabelValues = (id: number, value: string) => async (dispatch: Dispatch<ItemDispatchTypes>) => {
  try {
    dispatch({
      type: ITEM_LOADING,
      payload: 'ITEM_LABEL_VALUE'
    })
   
    const bodyForm = {
      id: id,
      value: value
    };
  
    const configPOST = {
      headers: {
        'Accept': '*/*',
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
      }
    };
    const res = await axios.put(`${ getBasePath(['admin']) }/updateLabelValues`, bodyForm, configPOST);
    if (res.status === 200) {
      dispatch({
        type: UPDATE_ITEM_LABEL_VALUE,
        payload: {labelValue: res.data.data, message: 'status.success-update'}
      })
    } else {
      dispatch({
        type: ITEM_FAIL,
        payload: 'status.error-update'
      })
    }

  } catch(e) {
    dispatch({
      type: ITEM_FAIL,
      payload: 'status.error-update'
    })
  }
};

/**
 * Indicate the labels were saved.
 */
 export const saveLabelsDone = () => async (dispatch: Dispatch<ItemDispatchTypes>) => {
  dispatch({
    type: ITEM_LABEL_SAVED,
    payload: 'status.success-labels-save'
  })
};

/**
 * Save a item discount on the BE.
 * @param {number} itemId
 * @param {number} pricePercentage
 * @param {number} quantity
 */
 export const registerItemDiscount = (itemId: number, pricePercentage: number, quantity: number) => async (dispatch: Dispatch<ItemDispatchTypes>) => {
  try {
    dispatch({
      type: ITEM_LOADING,
      payload: 'ITEM_DISCOUNT'
    })
   
    const bodyForm = {
      "itemId": itemId,
      "pricePercentage": pricePercentage,
      "quantity": quantity
    };
  
    const configPOST = {
      headers: {
        'Accept': '*/*',
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
      }
    };
    const res = await axios.post(`${ getBasePath(['admin']) }/registerItemDiscount`, bodyForm, configPOST);
    if (res.status === 200) {
      dispatch({
        type: ADD_ITEM_DISCOUNT,
        payload: 'status.success-update'
      })
    } else {
      dispatch({
        type: ITEM_FAIL,
        payload: 'status.error-create'
      })
    }

  } catch(e) {
    dispatch({
      type: ITEM_FAIL,
      payload: 'status.error-create'
    })
  }
};

/**
 * Update a item discount on the BE.
 * @param {number} itemId
 * @param {number} pricePercentage
 * @param {number} quantityToAdd
 */
 export const updateItemDiscount = (itemId: number, pricePercentage: number, quantityToAdd: number) => async (dispatch: Dispatch<ItemDispatchTypes>) => {
  try {
    dispatch({
      type: ITEM_LOADING,
      payload: 'ITEM_DISCOUNT'
    })
   
    const bodyForm = {
      "itemId": itemId,
      "pricePercentage": pricePercentage,
      "quantityToAdd": quantityToAdd,
      "removeItems": true
    };
  
    const configPOST = {
      headers: {
        'Accept': '*/*',
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
      }
    };
    const res = await axios.put(`${ getBasePath(['admin']) }/updateItemDiscount`, bodyForm, configPOST);
    if (res.status === 200) {
      dispatch({
        type: UPDATE_ITEM_DISCOUNT,
        payload: 'status.success-update'
      })
    } else {
      dispatch({
        type: ITEM_FAIL,
        payload: 'status.error-create'
      })
    }

  } catch(e) {
    dispatch({
      type: ITEM_FAIL,
      payload: 'status.error-create'
    })
  }
};


/**
 * Soft delete a given item.
 * @param {string} code
 */
 export const deleteItem = (code: string) => async (dispatch: Dispatch<ItemDispatchTypes>) => {
  if (apiURL !== '' && token !== '') {

    try {
      dispatch({
        type: ITEM_LOADING,
        payload: 'DELETE_ITEM'
      })

      const configPUT = {
        headers: {
          'Accept': '*/*',
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        }
      };
    
      const deleteRes = await axios.delete(`${ getBasePath(['admin']) }/deleteItem/${code}`, configPUT);

      if (deleteRes.status === 200) {
        dispatch({
          type: DELETE_ITEM,
          payload: 'status.success-delete-item'
        })
      }
       
    } catch(e) {
      dispatch({
        type: ITEM_FAIL,
        payload: 'status.delete-error-item'
      })
    }
  } else {
    dispatch({
      type: ITEM_FAIL,
      payload: 'status.delete-error-item'
    })
  }
};

/**
 * Update a item on the BE.
 * @param {string} itemCode
 */
export const updateDescriptionSingle = (itemCode: string) => async (dispatch: Dispatch<ItemDispatchTypes>) => {
  try {
    dispatch({
      type: ITEM_LOADING,
      payload: 'SINGLE_ITEM_UPDATE_DESCRIP'
    })

    var bodyFormData = new FormData();
    itemCode && bodyFormData.append('code', itemCode);

    const configPUT = {
      headers: {
        'Accept': '*/*',
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'multipart/form-data',
      }
    };

    const res = await axios.put(`${getBasePath(['admin'])}/updateDescriptionSingle`, bodyFormData, configPUT);
    if (res.status === 200) {
      dispatch({
        type: ITEM_SINGLE_DESC_PUT,
        payload: 'status.success-update'
      })
    } else {
      dispatch({
        type: ITEM_FAIL,
        payload: 'status.error-update'
      })
    }

  } catch (e) {
    dispatch({
      type: ITEM_FAIL,
      payload: 'status.error-update'
    })
  }
};


/**
 * Returns the base path based on the role.
 */
const getBasePath = (roles:Array<string>) => {
  if (!token) {
    return `${apiURL}/guest`;
  } else {
    const decodedToken:any = jwtDecode(String(token));
    if (decodedToken.auth.includes('ROLE_USER') && roles.includes('user')) {
      return `${apiURL}/user`;
    }
    if (decodedToken.auth.includes('ROLE_SELLER') && roles.includes('seller')) {
      return `${apiURL}/seller`;
    }
    if ((decodedToken.auth.includes('ROLE_ADMIN') || decodedToken.auth.includes('ROLE_SUPER_SELLER')) && !roles.includes('admin') && roles.includes('seller')) {
      return `${apiURL}/seller`;
    }
    if ((decodedToken.auth.includes('ROLE_ADMIN') || decodedToken.auth.includes('ROLE_SUPER_SELLER')) && roles.includes('admin')) {
      return `${apiURL}/admin`;
    }
    return `${apiURL}/user`;
  }
};
