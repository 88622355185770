// React.
import React, { useEffect, useState, Fragment } from 'react';

// Material UI.
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Avatar from '@material-ui/core/Avatar';
import DeleteIcon from '@material-ui/icons/Delete';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import Typography from '@material-ui/core/Typography';

// Editor
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// Redux
import {useDispatch, useSelector} from "react-redux";
import { addItem, getItemLabels } from "../../../actions/itemActions/ItemActions";
import { getItemTypes } from "../../../actions/itemTypeActions/ItemTypeActions";
import { getVendors } from '../../../actions/vendorActions/VendorActions';
import {RootStore} from "../../../Store";

// Global Types.
import { ItemType, ItemTypes, ItemCurrencyType, ItemVendorType, ItemQuantity } from '../models/ModelTypes';

// Translations.
import { useTranslation } from 'react-i18next';

// Styles.
import useStyles from './AddItemFormStyles';
import defaultImage from '../../../images/default-img.jpg';

const AddItemForm = () => {

  const [t] = useTranslation('global');
  const classes = useStyles();
  const dispatch = useDispatch();

  // Define default stage values.
  const itemCategoryType:ItemTypes = {id: 0, taxPercentage: 0, name: '', image_url: '', description: '', isDeleted: false};
  const itemCurrencyType:ItemCurrencyType = {id: 0, name: '', symbol: '', code: '', isDeleted: false};
  const itemVendorType:ItemVendorType = {
    id: 0
  };
  const defaultItem:ItemType = { 
    id: 0, 
    name: '', 
    price: 0,
    state: '',
    code: '',
    weightPerUnit: 0,
    minimumQuantity: 0,
    costPrice: 0,
    notes: '',
    imagesIdToRemove: '',
    labelValuesIdsToAdd: '',
    labelValuesIdsToRemove: '',
    isDeleted: false,
    itemType: itemCategoryType,
    currency: itemCurrencyType,
    quantities: [],
    vendor: itemVendorType,
    warehouseId: 0,
    warehousesQuantities: [],
    labelValues: [],
    images: [],
    discounts: [],
    priceWithTaxes: 0,
    creationDate: '',
    isForSale:true
  };

  // Redux stage.
  const reduxState = useSelector((state: RootStore) => state.item);
  const loading = reduxState.loading;
  const labelList = reduxState.itemLabelList;
  const reduxCategory = useSelector((state: RootStore) => state.itemType);
  const itemCategoryList = reduxCategory.itemTypeList?.categories;
  const reduxVendors = useSelector((state: RootStore) => state.vendors);
  const { vendors } = reduxVendors;

  // Local component state.
  const [itemState, setItemState] = useState<ItemType>(defaultItem);
  const [errorArray, setErrorArray] = useState<Array<string>>([]);
  const [itemLabels, setItemLabels] = React.useState<number[]>([]);
  const [sellPrice, setSellPrice] = React.useState<string>('0');

  useEffect(() => {
    dispatch(getItemTypes());
    dispatch(getVendors());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const quantities = vendors.map( vendor => {
      return {
        vendorId: `${vendor.id}`,
        quantity: '0'
      }
    })
    setItemState({
      ...itemState,
      quantities: quantities
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendors])

  useEffect(() => {
    if (itemState.itemTypeId && itemState.itemTypeId !== 0) {
      dispatch(getItemLabels(itemState.itemTypeId));
      setItemLabels([]);
    } else {
      dispatch(getItemLabels(0));
      setItemLabels([]);
    }
    if (itemState.itemTypeId !== undefined) {
      setItemState({
        ...itemState,
        price: postProcessPrice(Number(sellPrice))
      });
    }
    // eslint-disable-next-line
  }, [itemState.itemTypeId]);

  const changeSellPrice = (event: React.ChangeEvent<{value: unknown }>) => {
    setSellPrice(String(event.target.value));
    setItemState({
      ...itemState,
      price: postProcessPrice(Number(event.target.value))
    });

    // Update errors list.
    const errors = [...errorArray];
    const newErrors = errors.filter(function(value) { 
      return value !== 'price';
    });
    setErrorArray(newErrors);
  };

  const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const name = event.target.name as keyof typeof itemState;
    var currentValue = event.target.value;
    var warehouseId = 1;

    // Filter incorrect numbers
    if (name === 'price' || name === 'costPrice' 
        || name === 'weightPerUnit' || name === 'minimumQuantity' || name.includes('warehouse_')) {
      const re = /^[0-9\b]+$/;
      const newValue = String(currentValue);
      if (!re.test(newValue)) {
        currentValue = '';
      }

    }
  
    setItemState({
      ...itemState,
      warehouseId: warehouseId,
      [name]: currentValue
    });

    // Validate and add errors.
    const errors = [...errorArray];
    const nameString = '' + [name]
    if (currentValue === '') {
      errors.push(nameString);
      setErrorArray(errors);
    } else {
      const newErrors = errors.filter(function(value) { 
        return value !== nameString;
      });
      setErrorArray(newErrors);
    }
  };

  const handleUploadClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filesList = event.target.files as File[] | null;
    const imgs = itemState.images;
    const files = itemState.files ? itemState.files : [];
    for (var i = 0; i < filesList!.length; i++) {
      let tempURL = URL.createObjectURL(filesList![i]);
      const uniqueId = (new Date().getTime()) + i;
      const newImg = {
        id: uniqueId,
        url: tempURL,
        key: tempURL
      };
      imgs?.push(newImg);
      files?.push({fid: uniqueId, file: filesList![i]});
    }
    setItemState({
      ...itemState,
      images: imgs,
      files: files
    });
  };

  const handleLabelChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newValue = event.target.value as number[];
    setItemLabels(newValue);
    setItemState({
      ...itemState,
      labelValuesIds: newValue.toString()
    });
  };

  const handleDeleteImg = (id: number) => {
    const newImages = itemState.images!.filter((image) => image.id !== id)
    const newFiles = itemState.files!.filter((file) => file.fid !== id)
    if (newImages !== undefined) {
      setItemState({
        ...itemState,
        images: newImages,
        files: newFiles
      });
    }
  };

  const validateFields = () => {
    var result = true;
    const errors = [...errorArray];
    if (itemState.name === '') {
      errors.push('name');
      result = false;
    }
    if (itemState.code === '') {
      errors.push('code');
      result = false;
    }
    if (itemState.price < 1) {
      errors.push('price');
      result = false;
    }
    if (itemState.weightPerUnit < 1) {
      errors.push('weightPerUnit');
      result = false;
    }
    if (itemState.minimumQuantity < 1) {
      errors.push('minimumQuantity');
      result = false;
    }
    if (itemState.notes === '') {
      errors.push('notes');
      result = false;
    }
    if (itemState.itemTypeId === undefined) {
      errors.push('itemTypeId');
      result = false;
    }

    setErrorArray(errors);

    return result;
  };

  const handleChangeValidation = (inputName: string) => {
    var result = false;
    errorArray.forEach((key) => {
      if (key === inputName) {
        result = true;
      }
    });

    return result;
  };

  const handleQuantitiesChange = (value: string,id:number)=>{

    if (itemState.quantities && Number(value) > -1) {
      const newQuantities:ItemQuantity[] = itemState.quantities.map( (quantity) => {
        if (quantity.vendorId === id.toString()) {
          const newQuantity = {
            vendorId: quantity.vendorId,
            quantity: value
          }
          return newQuantity
        }
        return quantity
      })
      setItemState({
        ...itemState,
        quantities: newQuantities
      })
    }
  }

  const postProcessPrice = (price:number) => {
    var tax = 0;
    if (itemCategoryList !== undefined) {
      if (itemState?.itemTypeId !== undefined) {
        const updateList = itemCategoryList!.filter((category) => category.id === itemState?.itemTypeId);
        tax = updateList[0].taxPercentage;
      } else {
        tax = itemCategoryList[0].taxPercentage;
      }
    }
    return Number(((price * 100) / (100 + tax)).toFixed(2));
  };

  const saveOnClick = () => {
    // Save the item product on the BE.
    const validation = validateFields();
    if (errorArray.length === 0 && validation) {
      dispatch(addItem(itemState));
    }
  };

  // Clear fields when success.
  useEffect(() => {
    if (reduxState.actionStatus !== undefined) {
      if (reduxState.actionStatus.status === 'success') {
        handleClear();
      }
    }

    // eslint-disable-next-line
  }, [reduxState.actionStatus]);

  const getVendorFieldValue = (vendorId:number) => {
    const quantities = itemState.quantities
    let current = quantities?.filter((obj:ItemQuantity) => Number(obj.vendorId) === vendorId);
    if (current!.length > 0) {
      return current![0].quantity;
    }
  };

  const handleClear = () => {
    const quantities = itemState.quantities
    for (var i = 0; i < quantities!.length; i++){
      quantities![i].quantity = '0';
    }
    defaultItem.quantities = quantities;
    // First clear the notes.
    setItemState({...itemState, notes: ''});
    setTimeout(function () {
      // Clear the rest.
      setItemState(defaultItem);
      setSellPrice('0');
    }, 700);
  };

  const isValidVendor = (vendor:any) => {
    var result = false;
    vendor.vendorCategories.forEach((category: string) => {
      if (category === 'Sellado') result = true;
    });
    return result;
  };

  return (
    <form noValidate autoComplete="off" className="add-form">
      <CssBaseline />
      <div className="left-side">
        <TextField 
          id="item-name" label={ t('addItemForm.item-name') } 
          name="name" 
          color="primary" 
          value={ itemState.name }
          fullWidth 
          autoFocus
          onChange={ handleChange } 
          error={ handleChangeValidation('name') }
          helperText={handleChangeValidation('name') ? t('users.field-required'): ''}
        />
        <TextField 
          id="item-code" label={ t('addItemForm.item-code') } 
          name="code" 
          color="primary" 
          value={ itemState.code }
          fullWidth 
          onChange={ handleChange } 
          error={ handleChangeValidation('code') }
          helperText={handleChangeValidation('code') ? t('users.field-required'): ''}
        />
        <TextField 
          id="item-price"
          name="sellprice"
          label={ t('items.sell-price') }  
          value={ sellPrice }
          type="number"
          color="primary" 
          fullWidth
          onChange={ changeSellPrice } 
          error={ handleChangeValidation('price') }
          helperText={handleChangeValidation('price') ? t('users.field-required'): ''}
          onKeyDown={ (evt) => evt.key.toLowerCase() === 'e' && evt.preventDefault() }
        />
        <TextField 
          id="item-price"
          name="price-w" 
          label={ t('addItemForm.item-price') }  
          value={ itemState.price }
          type="number"
          color="primary" 
          fullWidth
          disabled
        />
        <TextField 
          id="item-cost-price"
          name="costPrice" 
          label={ t('addItemForm.item-cost-price') }
          value={ itemState.costPrice }
          type="number"  
          color="primary" 
          fullWidth
          onChange={ handleChange } 
          error={ handleChangeValidation('costPrice') }
          helperText={handleChangeValidation('costPrice') ? t('users.field-required'): ''}
          onKeyDown={ (evt) => evt.key.toLowerCase() === 'e' && evt.preventDefault() }
          />
        <TextField 
          id="item-unit-weight" 
          name="weightPerUnit"
          label={ t('addItemForm.item-unit-weight') } 
          value={ itemState.weightPerUnit }
          type="number" 
          color="primary" 
          fullWidth
          onChange={ handleChange } 
          error={ handleChangeValidation('weightPerUnit') }
          helperText={handleChangeValidation('weightPerUnit') ? t('users.field-required'): ''}
          onKeyDown={ (evt) => evt.key.toLowerCase() === 'e' && evt.preventDefault() }
        />
        <TextField 
          id="item-minimum-quantity"
          name="minimumQuantity" 
          label={ t('addItemForm.item-minimum-quantity') }  
          value={ itemState.minimumQuantity }
          type="number"
          color="primary" 
          fullWidth
          onChange={ handleChange } 
          error={ handleChangeValidation('minimumQuantity') }
          helperText={handleChangeValidation('minimumQuantity') ? t('users.field-required'): ''}
          onKeyDown={ (evt) => evt.key.toLowerCase() === 'e' && evt.preventDefault() }
        />
        <InputLabel className={classes.descripLabel}>{t('addItemForm.item-description')}</InputLabel>
        <CKEditor
          editor={ClassicEditor}
          id="item-description"
          data={itemState.notes}
          config={{ toolbar: ['bold', 'italic', 'bulletedList', 'numberedList', 'blockQuote', '|', 'undo', 'redo', '|', 'InsertTable'] }}
          onChange={(event, editor) => {
            const data = editor.getData();
            setItemState({
              ...itemState,
              notes: data
            });
            // Validate and add errors.
            const errors = [...errorArray];
            if (data !== '') {
              const newErrors = errors.filter(function (value) {
                return value !== 'notes';
              });
              setErrorArray(newErrors);
            }
          }}
        />
        {handleChangeValidation('notes') && 
          <Typography variant="caption" color="error">{t('users.field-required')}</Typography>
        }
        <FormControl className={classes.formControl} error={ handleChangeValidation('itemTypeId') }>
          <InputLabel id="item-category-label">{ t('addItemForm.item-types') }</InputLabel>
          <Select
            labelId="item-category-label"
            name="itemTypeId"
            id="item-category"
            onChange={ handleChange } 
            value={itemState?.itemTypeId !== undefined ? itemState?.itemTypeId : ''}
            error={ handleChangeValidation('itemTypeId') }
          >
            {
            itemCategoryList !== undefined &&
            (itemCategoryList?.map((category) => 
              <MenuItem key={ category.id } value={ category.id }>{ category.name }</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="mutiple-labels">{ t('addItemForm.item-label') }</InputLabel>
          <Select
            labelId="mutiple-labels"
            id="mutiple-labels"
            multiple
            value={ itemLabels }
            onChange={handleLabelChange}
            input={ <Input /> }
            name="labelValues"
          >
            { labelList?.length === undefined ?
              <MenuItem value="">
                { t('items.none') }
              </MenuItem> :
              labelList?.map(label => 
                label.labelValues?.map(labelValue => 
                  <MenuItem key={labelValue.id} value={labelValue.id}>
                    {`${label.value}:${labelValue.value}`}
                  </MenuItem>
                )
              )
            }
          </Select>
        </FormControl>
          {
            vendors !== undefined && 
            (vendors.map((vendor,index) =>
              <Fragment key={index}>
                {isValidVendor(vendor) &&
                  <TextField
                    key={index}
                    name="minimumQuantity"
                    label={`Cantidad para ${vendor.name}`}
                    type="number"
                    color="primary"
                    value={getVendorFieldValue(vendor.id)}
                    onChange={(event) => {
                      handleQuantitiesChange(event.target.value, vendor.id)
                    }}
                    onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                    fullWidth
                  />
                }
              </Fragment>
            ))
          }
      </div>
      <div className="right-side">
        <div className={ classes.imgWrapper }>
            <div>
              {itemState.images![0] && 
                <DeleteIcon color="secondary" className={ classes.deleteIcon } onClick={() => handleDeleteImg(itemState.images![0].id) }/>
              }
              <Avatar variant="rounded" alt="Item image" src={ itemState.images![0] ? itemState.images![0].url : defaultImage } className={ classes.mainImg }/>
            </div>
            <div className={classes.smallImgWraper}>
              { // eslint-disable-next-line
              itemState.images!.map(image =>
                { if (itemState.images![0].id !== image.id)
                  return (
                    <div className={ classes.smallImg } key={image.url}>
                      <DeleteIcon color="secondary" className={ classes.deleteIcon } onClick={() => handleDeleteImg(image.id) }/>
                      <Avatar variant="rounded" alt="Item image" className={classes.smallImg} src={ image.url}/>
                    </div>
                  )
                }
              )}
            </div>
            <div className={ classes.editImgWrapper }>
                <input accept="image/*" id="icon-button-file" type="file" multiple onChange={ handleUploadClick }/>
                <label htmlFor="icon-button-file">
                <Button variant="contained" color="primary" component="span">
                  { t('items.add-image') }
                </Button>
                </label>
            </div>
        </div>
      </div>
      <div className="form-controls ">
        { (loading === 'ADD_ITEM') && <CircularProgress /> }
        <Button variant="outlined" color="secondary" onClick={ handleClear }> { t('items.clear') } </Button>
        <Button variant="contained" color="primary" onClick={ saveOnClick } disabled={ errorArray.length !== 0 }> { t('items.save') } </Button>
      </div>
    </form>
  )
}

export default AddItemForm;
