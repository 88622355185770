// React.
import React from 'react';

import ReactFacebookLoginProps from 'react-facebook-login';

// Material UI.
import CircularProgress from '@material-ui/core/CircularProgress';

// Redux.;
import { useDispatch, useSelector } from "react-redux";
import { loginUserFacebook } from "../../../actions/authActions/AuthActions";
import { RootStore } from "../../../Store";

// Styles.
import useStyles from './LoginSocialButtonStyles';

// Translations.
import { useTranslation } from 'react-i18next';

const FBLoginButton = () => {
  const classes = useStyles();
  const [t] = useTranslation('global');
  const clientId = process.env.REACT_APP_FACEBOOK_APP_ID;
  const baseURL = window.location.origin;

  const dispatch = useDispatch();
  const authReduxState = useSelector((state: RootStore) => state.auth);
  const { loading } = authReduxState;

  const responseGoogleSuccess = (response:any) => {
    if (response.accessToken !== undefined) {
      dispatch(loginUserFacebook(response.accessToken));
    }
  }

  return (
    <div className={ classes.loginButton }>
    { loading === 'LOGIN_USER_FB' ? <CircularProgress /> :
      <ReactFacebookLoginProps
        appId={String(clientId)}
        fields="name,picture,email"
        callback={responseGoogleSuccess}
        textButton={ t('authentication.fb-login') }
        autoLoad={false}
        cssClass="facebook-login-button"
        icon="fab fa-facebook-square"
        scope="public_profile,email"
        disableMobileRedirect={true}
        redirectUri={ baseURL }
      />
    }
  </div>
  )
}

export default FBLoginButton;
