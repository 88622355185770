// React.
import React, { useState, Fragment, useEffect } from 'react';
import ShowMoreText from "react-show-more-text";

// Model Types.
import { ItemType } from '../models/ModelTypes';

//  JWT decode token.
import jwtDecode from 'jwt-decode';

// Moment.
import Moment from 'moment';

// Redux
import { useDispatch, useSelector } from "react-redux";
import { 
  updateItem,
  registerItemDiscount,
  updateItemDiscount,
  deleteItem,
  updateItemPublished,
  notifyPriceQuantityUpdateStatus,
  updateIsForSale
} from "../../../actions/itemActions/ItemActions";
import { RootStore } from "../../../Store";

// Material UI.
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteIcon from '@material-ui/icons/Delete';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// Editor
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// Translations.
import { useTranslation } from 'react-i18next';

// Styles.
import { useStyles, Accordion, AccordionSummary, AccordionDetails } from './ItemEditStyles';
import defaultImage from '../../../images/default-img.jpg';
import axios from 'axios';

// Components.
import NumberFormat from '../utils/NumberFormat';

interface ItemEditProps {
  item: ItemType;
  isExpanded: boolean;
};

type itemInfo = {
  item: ItemType;
  vendorName: string;
  price: number;
  sellprice: number;
  tax: number;
  quantity: number;
  totalQuantity: number;
  currency: any;
  isForSale:boolean;
}

type discountInfo = {
  itemId: number;
  percentage: number;
  quantity: number;
}

const ItemEdit = ({ item, isExpanded }: ItemEditProps) => {
  const [t] = useTranslation('global');
  const classes = useStyles();
  const dispatch = useDispatch();
  const decodedToken: any = localStorage.token !== undefined && localStorage.token !== '' ? jwtDecode(localStorage.token) : false;
  var loggedUserRole = '';
  if (localStorage.token !== undefined && localStorage.token !== '') {
    loggedUserRole = decodedToken.auth;
  }

  // Get item label ids.
  var labelIds: any = [];
  for (var i = 0; i < item.labelValues!.length; i++) {
    if (item.labelValues![i]) {
      labelIds.push(item.labelValues![i].id);
    }
  }

  // Local component state.
  const [itemState, setItemState] = useState<ItemType>(() => item);
  const [uniqueItems, setUniqueItems] = useState<ItemType[]>();
  const [adjustQtyValues, setAdjustQtyValues] = useState<Array<string>>([]);
  const [fieldError, setFieldError] = useState<boolean | false>(false);
  const [itemLabels, setItemLabels] = React.useState<number[]>(labelIds);
  const [quantitiesAndPrices, setQuantitiesAndPrices] = useState<itemInfo[]>();
  const [editingDetails, setEditingDetails] = useState<boolean | false>(false);
  const [editingPriceQuantity, setEditingPriceQuantity] = useState<Array<boolean>>([]);
  const [cacheQuantities, setCacheQuantities] = useState<itemInfo[]>();
  const [discountList, setDiscountList] = useState<any>({ discounts: [] });
  const [loadingLocal, setLoadingLocal] = useState<boolean>(false);
  const [loadingLocalQty, setLoadingLocalQty] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [pendingSaveQuantityIndex, setPendingSaveQuantityIndex] = useState<number>(-1);
  const [isForSale, setIsForSale] = useState(false)
  const [uniqueItemsCount,setUniqueItemsCount]=useState(0)

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  }

  // Redux state.
  const reduxItem = useSelector((state: RootStore) => state.item);
  const loading = reduxItem.loading;
  const labelList = reduxItem.itemLabelList;
  const reduxVendors = useSelector((state: RootStore) => state.vendors);
  const { vendors } = reduxVendors;
  const vendorLoading = reduxVendors.loading;
  const [expanded, setExpanded] = useState<string | false>(false);

  const getItemsByCode = async (code: string) => {
    const apiURL: string | undefined = process.env.REACT_APP_API_URL;
    const token: string | undefined = localStorage.token;

    const headers: object = {
      ContentType: 'application/json',
      Authorization: 'Bearer ' + token
    }

    const res = await axios.get(`${apiURL}/seller/getAllItems`, {
      params: { code: code },
      headers: headers
    });

    if (res.status === 200) {
      const queryData = res.data.data.content;
      const items: any = [];
      const discounts: any = [];
      for (var i = 0; i < queryData.length; i++) {
        const item = queryData[i];
        items.push({
          id: item.id,
          name: item.name,
          price: item.price,
          commission: item.commission,
          state: item.state,
          code: item.code,
          weightPerUnit: item.weightPerUnit,
          minimumQuantity: item.minimumQuantity,
          costPrice: item.costPrice,
          customPrices: item.customPrices,
          additionalPrice: item.additionalPrice,
          notes: item.notes,
          isDeleted: item.isDeleted,
          itemType: item.itemType,
          currency: item.currency,
          vendor: item.vendor,
          totalQuantity: item.totalQuantity,
          warehousesQuantities: item.warehousesQuantities,
          warehousesCustomQuantities: item.warehousesCustomQuantities,
          labelValues: item.labelValues,
          images: item.images,
          discounts: item.discount,
          priceWithTaxes: item.priceWithTaxes,
          creationDate: item.creationDate,
          isPublished: item.isPublished,
          category: item.category,
          automaticPrice: item.automaticPrice,
          isForSale:item.isForSale
        });

        if (item.discount !== null) {
          discounts.push({
            itemId: item.id,
            percentage: item.discount.pricePercentage,
            quantity: getDiscountQuantity(item)
          });
        }
      }
      setUniqueItems(items);
      setDiscountList({ discounts: discounts });
      setLoadingLocal(false);
    }
  }


  const getDiscountQuantity = (item: ItemType) => {
    var quantity = '0';
    item.warehousesQuantities.forEach((whQuantity) => {
      if (whQuantity.warehouse.isDiscount) {
        quantity = String(whQuantity.quantity);
      }
    });
    return quantity;
  };

  const updateIndividualItem = async (updatedItem: itemInfo) => {
    setLoadingLocalQty(true);
    const apiURL: string | undefined = process.env.REACT_APP_API_URL;
    const token: string | undefined = localStorage.token;
    const baseItem = cacheQuantities?.find(e => e.item.id === updatedItem.item.id);
    if (baseItem !== undefined) {
      const difQuantity = updatedItem.totalQuantity - baseItem.totalQuantity;
      // If the discount is grander then original total, first save item quiantity.
      const currrentDis = discountList.discounts.find((discount: discountInfo) => discount.itemId === baseItem.item.id);
      const currenQty = currrentDis !== undefined ? currrentDis.quantity : 0;
      if (currenQty > baseItem.totalQuantity) {
        // Save the items quantity.
        const data = {
          id: updatedItem.item.id,
          price: postProcessPrice(updatedItem.sellprice, updatedItem.tax),
          quantity: difQuantity
        }
        const configPOST = {
          headers: {
            'Accept': '*/*',
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
          }
        };

        const res = await axios.put(`${apiURL}/admin/updateIndividualItem`, data, configPOST);

        if (res.status === 200) {
          await saveDiscount(baseItem.item.id);
          // Notify the status
          dispatch(notifyPriceQuantityUpdateStatus('success'));
          setLoadingLocalQty(false);
        } else {
          // Notify the status
          dispatch(notifyPriceQuantityUpdateStatus('error'));
          setLoadingLocalQty(false);
        }
      } else {
        const discountPromese = saveDiscount(baseItem.item.id);
        await discountPromese.then(
          async () => {
            // Save the items quantity.
            const data = {
              id: updatedItem.item.id,
              price: postProcessPrice(updatedItem.sellprice, updatedItem.tax),
              quantity: difQuantity
            }
            const configPOST = {
              headers: {
                'Accept': '*/*',
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
              }
            };

            const res = await axios.put(`${apiURL}/admin/updateIndividualItem`, data, configPOST);

            // Notify the status
            res.status === 200 ? dispatch(notifyPriceQuantityUpdateStatus('success')) : dispatch(notifyPriceQuantityUpdateStatus('error'));
            setLoadingLocalQty(false);
          }
        );
      }
    } else {
      setLoadingLocalQty(false);
    }
  }

  const saveDiscount = async (itemId: number) => {
    if (discountList.discounts.length > 0) {
      await discountList.discounts.map(async (discount: discountInfo) => {
        const currentItem = uniqueItems?.filter((item) => item.id === discount.itemId);
        if (currentItem !== undefined && (itemId === discount.itemId)) {
          if (currentItem[0].discounts === null) {
            await dispatch(registerItemDiscount(discount.itemId, discount.percentage, Number(discount.quantity)));
          } else {
            let percentage = discount.percentage !== undefined ?
              discount.percentage : 0;
            let discountQty = Number(discount.quantity) - Number(getDiscountQuantity(currentItem[0]));
            await dispatch(updateItemDiscount(discount.itemId, percentage, discountQty));
          }
        }
      });
    }
  };

  const getVendorNameById = (item: ItemType) => {
    const id = item.vendor?.id ?? 0;
    const vendor = vendors.find(vendor => vendor.id === id);
    if (vendor !== undefined) {
      return vendor?.name;
    } else {
      return item.vendor?.name;
    }
  }

  const getInitialPriceAndQty = (): itemInfo[] => uniqueItems?.map((item: ItemType): itemInfo => {
    const vendorName = getVendorNameById(item) ?? '';
    const tax = item.itemType?.taxPercentage !== undefined ? item.itemType?.taxPercentage : 0;
    return {
      vendorName: vendorName,
      item: item,
      price: item.price,
      sellprice: preProcessPrice(item.price, tax),
      tax: tax,
      quantity: item.warehousesQuantities![0]?.quantity,
      totalQuantity: Number(item.totalQuantity),
      currency: item.currency,
      isForSale:item.isForSale
    }
  }) ?? [];

  const getInitialDiscountInfo = () => {
    const discounts: any = [];
    for (var i = 0; i < uniqueItems!.length; i++) {
      const item = uniqueItems![i];
      if (item.discounts !== null) {
        discounts.push({
          itemId: item.id,
          percentage: item.discounts.pricePercentage,
          quantity: getDiscountQuantity(item)
        });
      }
    }
    setDiscountList({ discounts: discounts });
  };

  const updateQuantitiesAndPrices = (event: React.ChangeEvent<{
    id?: string;
    name: string;
    value: string;
  }>) => {
    const re = /^[0-9\b]+$/;
    if (re.test(event.target.value) || event.target.value === '') {
      const updated: itemInfo[] = quantitiesAndPrices?.map((item: itemInfo) => {
        if (`${item.item.id}` === event.target.id) {
          const updatedItem = {
            ...item,
            [event.target.name]: event.target.value !== '' ? Number(event.target.value) : 0
          }
          return updatedItem
        }
        return item
      }) ?? [];
      setQuantitiesAndPrices(updated)

      // If you it's need to update the discount quiantity.
      updateDiscountQty(Number(event.target.id), Number(event.target.value));
    }
  }

  const updateDiscountQty = (itemId: number, totalQty: number) => {
    const originalItem = uniqueItems?.find((item: ItemType) => item.id === itemId);
    const discountListCache = discountList?.discounts;
    const currentIndex = discountList?.discounts.findIndex((discount: discountInfo) => discount.itemId === itemId);
    if (originalItem?.totalQuantity !== undefined) {
      if (totalQty < originalItem.totalQuantity) {
        // Update discount quiantity down.
        const originalQty = Number(getDiscountQuantity(originalItem));
        const diference = originalItem?.totalQuantity - totalQty;
        const currentDiscount = discountListCache[currentIndex];
        var newDisQuantity = (originalQty - diference) >= 0 ? (originalQty - diference) : 0;
        discountListCache![Number(currentIndex)] = {
          ...currentDiscount,
          'quantity': newDisQuantity
        }
        setDiscountList({
          discounts: discountListCache
        });
      } else {
        // Update discount quantity to original.
        const originalQty = Number(getDiscountQuantity(originalItem))
        const currentDiscount = discountListCache[currentIndex];
        discountListCache![Number(currentIndex)] = {
          ...currentDiscount,
          'quantity': originalQty
        }
        setDiscountList({
          discounts: discountListCache
        });
      }
    }
  };

  const handleDiscountChange = (event: React.ChangeEvent<{ id?: string; name: string; value: string; }>, quantity: number) => {
    const discountIndex = discountList?.discounts.findIndex((discount: discountInfo) => String(discount.itemId) === event.target.id);
    const discountListCache = discountList?.discounts;
    const re = /^[0-9\b]+$/;
    if (re.test(event.target.value) || event.target.value === '') {
      if (discountIndex !== -1 && discountIndex !== undefined) {
        const currentDiscount = discountListCache![Number(discountIndex)];
        if (!(event.target.name === 'quantity' && Number(event.target.value) > quantity)) {
          discountListCache![Number(discountIndex)] = {
            ...currentDiscount,
            [event.target.name]: Number(event.target.value)
          }
        }
      } else {
        discountListCache?.push({
          itemId: Number(event.target.id),
          ...(event.target.name === 'quantity') ? { quantity: Number(event.target.value) } : { quantity: 0 },
          ...(event.target.name === 'percentage') ? { percentage: Number(event.target.value) } : { percentage: 0 },
        });
      }
      setDiscountList({
        discounts: discountListCache
      });
    }
  }

  const getItemDiscountPercentage = (itemId: number) => {
    const discountInfo = discountList?.discounts.filter((discount: discountInfo) => discount.itemId === itemId);
    if (discountInfo.length > 0) {
      return discountInfo[0] !== undefined ? discountInfo[0].percentage : 0
    }
    return 0;
  };

  const getItemDiscountQty = (itemId: number) => {
    const discountInfo = discountList?.discounts.filter((discount: discountInfo) => discount.itemId === itemId);
    if (discountInfo.length > 0) {
      return discountInfo[0] !== undefined ? discountInfo[0].quantity : 0
    }
    return 0;
  };


  useEffect(() => {
    const formPriceAndQty: itemInfo[] = getInitialPriceAndQty()
    if (formPriceAndQty.length !== 0) {
      setCacheQuantities(formPriceAndQty);
      setQuantitiesAndPrices(formPriceAndQty);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uniqueItems, vendors])

  useEffect(() => {
    if (isExpanded) {
      setLoadingLocal(true);
      getItemsByCode(itemState?.code ?? '')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxItem, isExpanded])

  useEffect(() => {
    // Validations.
    if (itemState.name === '' || itemState.code === '' || itemState.price.toString() === '') {
      setFieldError(true);
    } else {
      setFieldError(false);
    }
  }, [itemState])

  useEffect(() => {
    trackLabelChanges();
    // eslint-disable-next-line
  }, [itemLabels])

  useEffect(() => {
    setLoadingLocalQty(false);

    // eslint-disable-next-line
  }, [quantitiesAndPrices]);

  // Handles the field changes to update the state.
  const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const name = event.target.name as keyof typeof itemState;
    var currentValue = event.target.value;

    // Filter incorrect numbers
    if (name === 'price' || name === 'discounts'
      || name === 'weightPerUnit' || name === 'minimumQuantity' || name.includes('warehouse_')) {
      const re = /^[0-9\b]+$/;
      const newValue = String(currentValue);
      if (!re.test(newValue)) {
        currentValue = '';
      }
    }

    setItemState({
      ...itemState,
      [name]: currentValue,
    });
  };

  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setItemState({
      ...itemState,
      isPublished: event.target.checked,
    });

    // Save the published status right away.
    savePublishedStatus(`${event.target.checked}`, `${itemState.code}`);
  };

  const   handleChangeFeaturedCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setItemState({
      ...itemState,
      isFeature: event.target.checked,
    });
  };

  const handleUploadClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filesList = event.target.files as File[] | null;
    const imgs = itemState.images;
    const files = itemState.files ? itemState.files : [];
    for (var i = 0; i < filesList!.length; i++) {
      let tempURL = URL.createObjectURL(filesList![i]);
      const uniqueId = (new Date().getTime()) + i;
      const newImg = {
        id: uniqueId,
        url: tempURL,
        key: tempURL
      };
      imgs?.push(newImg);
      files?.push({ fid: uniqueId, file: filesList![i] });
    }
    setItemState({
      ...itemState,
      images: imgs,
      files: files
    });
  };

  const handleAdjustQtyOnChange = (event: React.ChangeEvent<{ value: unknown }>, index: number) => {
    var newValue = String(event.target.value);
    const re = /^[0-9\b]+$/;
    if (re.test(newValue) || newValue === '') {
      var copyAdjustQtyValues = [...adjustQtyValues];
      if (newValue !== '0') {
        // Removes leading zeros.
        newValue = String(Number(newValue));
      }
      copyAdjustQtyValues[index] = newValue !== '' ? newValue : '0';
      setAdjustQtyValues(copyAdjustQtyValues);
    }
  };

  const handleLabelChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setItemLabels(event.target.value as number[]);
  };

  const handleDeleteImg = (id: number) => {
    const newImages = itemState.images!.filter((image) => image.id !== id)
    const newFiles = itemState.files !== undefined ? itemState.files!.filter((file) => file.fid !== id) : [];
    const deletedImage = itemState.images!.filter((image) => image.id === id);
    if (newImages !== undefined) {
      var dIds = itemState.imagesIdToRemove;
      if (!deletedImage[0].url.includes('blob:')) {
        dIds = (itemState.imagesIdToRemove) ? (dIds + ',' + id) : ('' + id);
      }
      setItemState({
        ...itemState,
        images: newImages,
        files: newFiles,
        imagesIdToRemove: dIds
      });
    }
  };

  const adjustItemQuantity = (index: number, adjustValue: number, type: string) => {
    setLoadingLocalQty(!loadingLocalQty);
    if (quantitiesAndPrices !== undefined) {
      setLoadingLocal(true);
      // Create a copy from the state.
      const copyQuantitiesAndPrices = [...quantitiesAndPrices];

      // Update the quantity.
      const currentItemInfo = quantitiesAndPrices[index];
      const currentQty = currentItemInfo.totalQuantity;
      var newValue = type === 'add' ? Number(currentQty) + Number(adjustValue) : Number(currentQty) - Number(adjustValue);
      if (newValue < 0) {
        newValue = 0;
      }
      const updated: itemInfo = {
        ...currentItemInfo,
        totalQuantity: newValue
      };
      copyQuantitiesAndPrices[index] = updated;

      // Save the new state.
      setQuantitiesAndPrices(copyQuantitiesAndPrices);

      // Set the initial values for adjust fields.
      const copyAdjustQtyValues = [...adjustQtyValues];
      copyAdjustQtyValues.forEach((value: string, index: number) => {
        copyAdjustQtyValues[index] = '0';
      });
      setAdjustQtyValues(copyAdjustQtyValues);

      // Set the flag to save the changes.
      // Wait a white to make sure state is updated.
      setTimeout(function () {
        setPendingSaveQuantityIndex(index);
      }, 400);
    }
  };

  const trackLabelChanges = () => {
    let addedLabels = itemLabels.filter((id: number) => !labelIds.includes(id));
    let removedLabels = labelIds.filter((id: number) => !itemLabels.includes(id));

    setItemState({
      ...itemState,
      labelValuesIdsToAdd: addedLabels.toString(),
      labelValuesIdsToRemove: removedLabels.toString()
    });
  };

  const editOnClick = () => {
    setEditingDetails(true);
  };

  const editPriceQuantityOnClick = (index: number) => {
    var edittingCopy = [...editingPriceQuantity];
    edittingCopy[index] = true;
    setEditingPriceQuantity(edittingCopy);
  };

  const cancelOnClick = () => {
    setEditingDetails(false);
    // Set user state to original values.
    setQuantitiesAndPrices(getInitialPriceAndQty());
    setItemState(item);
    getInitialDiscountInfo();
    setIsForSale(uniqueItems? uniqueItems[0].isForSale:false)
  };

  const cancelPriceQuantityOnClick = (index: number) => {
    var edittingCopy = [...editingPriceQuantity];
    edittingCopy[index] = false;
    setEditingPriceQuantity(edittingCopy);

    // Set user state to original values.
    setQuantitiesAndPrices(getInitialPriceAndQty());
    getInitialDiscountInfo();
  };

  const preProcessPrice = (price: number, tax: number) => {
    return Math.round(price + (tax / 100) * price);
  };

  const postProcessPrice = (price: number, tax: number) => {
    return ((price * 100) / (100 + tax)).toFixed(2);
  };

  const isOtherQuantityEditing = (currentIndex: number) => {
    var result = false;
    editingPriceQuantity.forEach((value: boolean, i: number) => {
      if (value && currentIndex !== i) result = true;
    });
    if (vendorLoading !== '') result = true;
    return result;
  };

  // Auto-save quantity adjustments.
  useEffect(() => {
    if (pendingSaveQuantityIndex !== -1) {
      savePriceQuantityOnClick(pendingSaveQuantityIndex, false);
      
      // Reset the value.
      setPendingSaveQuantityIndex(-1);
    }
    // eslint-disable-next-line
  }, [pendingSaveQuantityIndex])

  const savePublishedStatus = async (isPublished: string, itemCode: string) => {
    // Save the general item data.
    dispatch(updateItemPublished(isPublished, itemCode));
  };

  const saveOnClick = async () => {
    // Save the general item data.
    dispatch(updateItem(itemState));
    setEditingDetails(false);

    // Clear the images uploaded
    setItemState({
      ...itemState,
      files: [],
    });
  };

  const savePriceQuantityOnClick = async (index: number, toClose = true) => {
    // Then save the unique item info.
    if (quantitiesAndPrices !== undefined) {
      updateIndividualItem(quantitiesAndPrices[index]);
    }

    // Update the editing state.
    if (toClose) {
      var edittingCopy = [...editingPriceQuantity];
      edittingCopy[index] = false;
      setEditingPriceQuantity(edittingCopy);
    }
  };

  useEffect(() => {
    if (reduxItem.item !== undefined) {
      // Update the images object with BE data.
      if (reduxItem.item.id === item.id) {
        setItemState({
          ...itemState,
          images: reduxItem.item.images,
        });
      }
    }
    // eslint-disable-next-line
  }, [reduxItem.item])

  const deleteUserOnClick = () => {
    if (itemState.code) {
      dispatch(deleteItem(itemState.code));
    }

    handleCloseModal();
  };
  //Handling isForSale Property

  const saveIsForSaleStatus = (status:string,code:string)=>{
    dispatch(updateIsForSale(status, code));
  }
  useEffect(()=>{
    if(uniqueItemsCount<1){   
      if(uniqueItems!==undefined){
        setIsForSale(uniqueItems[0].isForSale)
        setUniqueItemsCount(uniqueItemsCount+1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[uniqueItems])
  const handleIsForSaleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsForSale(event.target.checked)
    saveIsForSaleStatus(`${event.target.checked}`, `${itemState.code}`); 
  }
  useEffect(()=>{
    setItemState({
      ...itemState,
      isForSale: isForSale
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isForSale])

  const handleChangeAccordion = (panel: string, idAccordion: number) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
    cancelPriceQuantityOnClick(idAccordion);

    // Remove editing status when accordion changes.
    var edittingCopy = [...editingPriceQuantity];
    edittingCopy.forEach((item, index) => {
      edittingCopy[index] = false;
    });
    setEditingPriceQuantity(edittingCopy);
  };

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={3}>
          <div className={classes.leftColumnWrapper}>
            <div className={classes.imgWrapper}>
              <div>
                {(editingDetails && itemState.images![0]) && <DeleteIcon color="secondary" className={classes.deleteIcon} onClick={() => handleDeleteImg(itemState.images![0].id)} />}
                <Avatar variant="rounded" alt="Item image" src={itemState.images![0] ? itemState.images![0].url : defaultImage} className={classes.mainImg} />
              </div>
              <div className={classes.smallImgWraper}>
                { // eslint-disable-next-line
                  itemState.images!.map(image=> {
                    if (itemState.images![0].id !== image.id)
                      return (
                        <div key={image.id} className={classes.smallImg}>
                          {editingDetails && <DeleteIcon color="secondary" className={classes.deleteIcon} onClick={() => handleDeleteImg(image.id)} />}
                          <Avatar variant="rounded" alt="Item image" src={image.url} />
                        </div>
                      )
                  }
                  )}
              </div>
              {editingDetails && (
                <div className={classes.editImgWrapper}>
                  <input accept="image/*" id="icon-button-file" type="file" multiple onChange={handleUploadClick} />
                  <label htmlFor="icon-button-file">
                    <Button variant="contained" color="primary" component="span">
                      {t('items.add-image')}
                    </Button>
                  </label>
                </div>
              )}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={9}>
          <form>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12}>
                <Typography variant="body2" color="initial">{t('items.item-details')}</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <FormControl className={classes.formControl}>
                  <Typography variant="caption" color="initial">{t('items.sku')}</Typography>
                  <Typography variant="caption" color="primary">{itemState.code}</Typography>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <Typography variant="caption" color="initial">{t('items.last-update')}</Typography>
                  <Typography variant="caption" color="primary">{Moment(itemState.lastUpdate).format('MM/DD/YYYY - HH:mm')}</Typography>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <Typography variant="caption" color="initial">{t('items.name')}</Typography>
                  {editingDetails ?
                    <TextField
                      className={classes.formField}
                      id="i-name" name="name" color="primary" value={itemState.name}
                      onChange={handleChange} error={itemState.name === ''}
                      helperText={itemState.name === '' ? t('users.field-required') : ''}
                      autoFocus
                    /> :
                    <Typography variant="caption" color="primary">{itemState.name}</Typography>
                  }
                </FormControl>
                <FormControl className={classes.formControl}>
                  <Typography variant="caption" color="initial">{t('items.weight')}</Typography>
                  {editingDetails ?
                    <TextField
                      className={classes.formField}
                      id="i-weight-per-unit" name="weightPerUnit" value={itemState.weightPerUnit}
                      type="number" color="primary" onChange={handleChange}
                      onKeyDown={(evt) => evt.key.toLowerCase() === 'e' && evt.preventDefault()}
                    /> :
                    <Typography variant="caption" color="primary"><NumberFormat number={itemState.weightPerUnit} /> g</Typography>
                  }
                </FormControl>
                <FormControl className={classes.formControl}>
                  <Typography variant="caption" color="initial">{t('items.minimum-quantity')}</Typography>
                  {editingDetails ?
                    <TextField
                      className={classes.formField}
                      id="i-minimum-quantity" name="minimumQuantity" value={itemState.minimumQuantity} type="number"
                      color="primary" onChange={handleChange}
                      onKeyDown={(evt) => evt.key.toLowerCase() === 'e' && evt.preventDefault()}
                    /> :
                    <Typography variant="caption" color="primary"><NumberFormat number={itemState.minimumQuantity} /></Typography>
                  }
                </FormControl>
                <FormControl className={`${classes.formControl} ${classes.formControlMultiple}`}>
                  <Typography variant="caption" color="initial">{t('items.labels')}</Typography>
                  {editingDetails ?
                    <Select
                      labelId="mutiple-labels"
                      id="mutiple-labels"
                      multiple
                      value={itemLabels}
                      onChange={handleLabelChange}
                      input={<Input />}
                      name="labelValues"
                    >
                      {
                        labelList?.map(label =>
                          label.labelValues?.map(labelValue =>
                            <MenuItem key={labelValue.id} value={labelValue.id}>
                              {`${label.value}:${labelValue.value}`}
                            </MenuItem>
                          )
                        )
                      }
                    </Select> :
                    <div className="labelsWrapper">
                      {
                        labelList?.map(label =>
                          label.labelValues?.map(labelValue =>
                            <span key={labelValue.id}>
                              {itemLabels.some(id => id === labelValue.id) &&
                                <Chip avatar={<Avatar>{label.value}</Avatar>} label={labelValue.value} />}
                            </span>
                          )
                        )
                      }
                    </div>
                  }
                </FormControl>               
                <FormControl className={classes.formControl}>
                  <Typography variant="caption" color="initial">{t('items.notes')}</Typography>
                  {editingDetails ?
                    <div className={classes.ckeditor}>
                      <CKEditor
                        editor={ClassicEditor}
                        id="item-description"
                        data={itemState.notes}
                        config={{ toolbar: ['bold', 'italic', 'bulletedList', 'numberedList', 'blockQuote', '|', 'undo', 'redo', '|', 'InsertTable'] }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setItemState({
                            ...itemState,
                            notes: data
                          });
                        }}
                      />
                      {itemState.notes === '' &&
                        <Typography variant="caption" color="error">{t('users.field-required')}</Typography>
                      }
                    </div>
                    :
                    <Typography className={`extra-width ${classes.ckeditor}`} color="primary">
                      <ShowMoreText
                        lines={3}
                        more={t('items.see-more')}
                        less={false}
                        expanded={false}
                        truncatedEndingComponent={"... "}
                      >
                        <div dangerouslySetInnerHTML={{ __html: item.notes }}></div>
                      </ShowMoreText>
                    </Typography>
                  }
                </FormControl>
                <FormControl className={classes.formControl}>
                  <Typography variant="caption" color="initial">{t('items.state')}</Typography>
                  {editingDetails ?
                    <TextField
                      className={classes.formField}
                      id="i-state" name="state" value={itemState.state}
                      color="primary" onChange={handleChange}
                    /> :
                    <Typography variant="caption" color="primary">{itemState.state}</Typography>
                  }
                </FormControl>
                <FormControl className={classes.formControl}>
                  <FormControlLabel 
                    control={
                      <Checkbox checked={itemState.isFeature} 
                        onChange={handleChangeFeaturedCheckbox} name="isFeature" 
                        color="default" 
                      />} 
                    label={t('items.featured') }
                    disabled={!editingDetails}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <FormControlLabel control={
                    <Checkbox 
                      checked={itemState.isPublished} 
                      onChange={handleChangeCheckbox} 
                      name="isPublished" color="primary"
                      disabled={(!loggedUserRole.includes('ROLE_ADMIN') && !loggedUserRole.includes('ROLE_SUPER_SELLER')) } 
                    />} 
                  label={t('items.published')} />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <FormControlLabel control={
                    <Checkbox 
                      checked={isForSale} 
                      onChange={handleIsForSaleChange} 
                      name="isForSale" color="primary"
                      disabled={(!loggedUserRole.includes('ROLE_ADMIN'))} 
                    />} 
                  label={t('items.is-for-sale')} />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <div className={classes.formControl}>
                  <Typography variant="body2" color="initial">
                    {t('items.vendor-stock-and-price')}
                  </Typography>
                </div>
                <div className={classes.vendorsWrapper}>
                  {quantitiesAndPrices ? quantitiesAndPrices.map((itemInfo: itemInfo, index: number) => {
                      return <Accordion key={index} className="vendor-item" expanded={expanded === `panel-u-${index}`} onChange={handleChangeAccordion(`panel-u-${index}`, index as number)}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography className={classes.vendorName}>{itemInfo.vendorName}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid item xs={12} sm={12} md={12} key={index}>
                            <div className={`${classes.formControl} ${classes.formControlPrice}`}>
                              <Typography variant="caption" color="initial">
                                {`${t('items.sell-price') }:`}
                              </Typography>
                              {(editingPriceQuantity[index]) ?
                                <Fragment>
                                  <TextField
                                    className={classes.formField} type="text" name="sellprice" color="primary"
                                    id={`${itemInfo.item.id}`}
                                    onChange={(e) => updateQuantitiesAndPrices(e)}
                                    value={itemInfo.sellprice}
                                    onKeyDown={(evt) => evt.key.toLowerCase() === 'e' && evt.preventDefault()}
                                  />
                                  <Typography variant="caption" color="initial">{`Precio:`}</Typography>
                                  <TextField
                                    className={classes.formField} type="text" name="price" color="primary"
                                    id={`${itemInfo.item.id}`}
                                    onChange={(e) => updateQuantitiesAndPrices(e)}
                                    value={postProcessPrice(itemInfo.sellprice, itemInfo.tax)}
                                    onKeyDown={(evt) => evt.key.toLowerCase() === 'e' && evt.preventDefault()}
                                    disabled
                                  />
                                </Fragment> :
                                <Typography className={classes.formField} variant="caption" color="initial">{itemInfo.currency.symbol}<NumberFormat number={itemInfo.sellprice} /></Typography>
                              }
                              <Typography variant="caption" color="initial">{`Cantidad Total:`}</Typography>
                              {(editingPriceQuantity[index] && decodedToken.auth.includes('ROLE_ADMIN')) ?
                                <Fragment>
                                  <TextField
                                    type="text"
                                    id={`${itemInfo.item.id}`}
                                    name="totalQuantity"
                                    color="primary"
                                    onChange={(e) => updateQuantitiesAndPrices(e)}
                                    value={loadingLocal ? ('...') : itemInfo.totalQuantity}
                                    disabled
                                    onKeyDown={(evt) => evt.key.toLowerCase() === 'e' && evt.preventDefault()}
                                  />
                                  <FormControl className={`${classes.formControl} ${classes.formControlAdjustQty}`}>
                                    <Typography variant="caption" color="initial">{t('items.adjust-qty')}</Typography>
                                    <TextField
                                      type="text"
                                      id={`${itemInfo.item.id}-qty`}
                                      name="adjustQuantity"
                                      color="primary"
                                      onChange={(e) => handleAdjustQtyOnChange(e, index)}
                                      value={adjustQtyValues[index] ? adjustQtyValues[index] : '0'}
                                      onKeyDown={(evt) => evt.key.toLowerCase() === 'e' && evt.preventDefault()}
                                    />
                                    <Button
                                      variant="outlined"
                                      color="primary"
                                      size="small"
                                      startIcon={<AddCircleOutlineIcon />}
                                      disabled={String(adjustQtyValues[index]) === '0' || adjustQtyValues[index] === undefined}
                                      onClick={() => adjustItemQuantity(index, Number(adjustQtyValues[index]), 'add')}>
                                      {t('items.add')}
                                    </Button>
                                    <Button
                                      variant="outlined"
                                      color="primary"
                                      size="small"
                                      startIcon={<RemoveCircleOutlineIcon />}
                                      disabled={String(adjustQtyValues[index]) === '0' || adjustQtyValues[index] === undefined}
                                      onClick={() => adjustItemQuantity(index, Number(adjustQtyValues[index]), 'remove')}>
                                      {t('items.remove')}
                                    </Button>
                                  </FormControl>
                                </Fragment>
                                :
                                <Typography variant="caption" color="initial">{loadingLocal ? itemInfo.totalQuantity + '...' : itemInfo.totalQuantity}</Typography>
                              }
                            </div>
                            <FormControl className={`${classes.formControl} ${classes.formControlDiscount} ${!editingPriceQuantity[index] ? 'closed' : ''}`}>
                              <Typography variant="caption" color="initial">{t('items.discounts')}</Typography>
                              {(editingPriceQuantity[index] && decodedToken.auth.includes('ROLE_ADMIN')) ?
                                <Fragment>
                                  <TextField
                                    className={`${classes.formField} ${classes.rightMargin}`}
                                    id={`${itemInfo.item.id}`} name="percentage" value={getItemDiscountPercentage(itemInfo.item.id)}
                                    type="text" color="primary" onChange={(e) => handleDiscountChange(e, itemInfo.totalQuantity)}
                                    label={t('items.percentage')}
                                    onKeyDown={(evt) => evt.key.toLowerCase() === 'e' && evt.preventDefault()}
                                    disabled={itemInfo.totalQuantity < 1}
                                  />
                                  <TextField
                                    className={classes.formField}
                                    id={`${itemInfo.item.id}`} name="quantity" value={getItemDiscountQty(itemInfo.item.id)}
                                    type="text" color="primary" onChange={(e) => handleDiscountChange(e, itemInfo.totalQuantity)}
                                    label={t('items.quantity')}
                                    onKeyDown={(evt) => (evt.key.toLowerCase() === 'e') && evt.preventDefault()}
                                    disabled={itemInfo.totalQuantity < 1}
                                  />
                                  {itemInfo.totalQuantity < 1 && <Typography variant="caption" color="primary">{t('items.error-discount-no-stock')}</Typography>}
                                </Fragment> :
                                <Typography variant="caption" color="primary">{getItemDiscountPercentage(itemInfo.item.id)}%</Typography>
                              }
                            </FormControl>
                            <div className={`${classes.actionsWrapper} ${classes.actionsWrapperPrice}`}>
                              {(editingPriceQuantity[index]) && (
                                <Fragment>
                                  {loading === 'ITEM_UPDATE' && <CircularProgress />}
                                  <Button variant="text" color="primary" size="small" 
                                    onClick={() => savePriceQuantityOnClick(index)} 
                                    disabled={fieldError || loadingLocalQty || loading !== ''}>
                                    {t('items.save')}
                                  </Button>
                                </Fragment>
                              )}
                              {(editingPriceQuantity[index]) && (
                                <Button variant="contained" color="secondary" size="small" onClick={() => cancelPriceQuantityOnClick(index)}>
                                  {t('items.cancel')}
                                </Button>
                              )}
                            </div>
                            {(!editingPriceQuantity[index] && decodedToken.auth.includes('ROLE_ADMIN')) && (
                              <div className={`${classes.actionsWrapper} ${classes.actionsWrapperPrice}`}>
                                <Button variant="outlined" color="primary" size="small" onClick={() => editPriceQuantityOnClick(index)} disabled={isOtherQuantityEditing(index)}>
                                  {t('items.edit-item-price-quantity')}
                                </Button>
                              </div>
                            )}
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                  }) : <CircularProgress className={classes.vendorLoader}/>}
                </div>
              </Grid>
            </Grid>
            <FormControl className={classes.formControl}>
              {editingDetails && (
                <div className={classes.actionsWrapper}>
                  {loading === 'ITEM_UPDATE' && <CircularProgress />}
                  <Button variant="text" color="primary" onClick={saveOnClick} disabled={fieldError || loading !== ''}>
                    {t('items.save')}
                  </Button>
                  <Button variant="contained" color="secondary" onClick={cancelOnClick}>
                    {t('items.cancel')}
                  </Button>
                  <Button variant="contained" color="primary" disabled>
                    {t('items.edit-item')}
                  </Button>
                </div>
              )}
              {(!editingDetails && decodedToken.auth.includes('ROLE_ADMIN')) && (
                <div className={classes.actionsWrapper}>
                  <Button variant="contained" color="primary" onClick={editOnClick}>
                    {t('items.edit-item')}
                  </Button>
                  {(loggedUserRole.includes('ROLE_ADMIN') || loggedUserRole.includes('ROLE_SUPER_SELLER')) &&
                    <Button variant="contained" color="secondary" startIcon={<DeleteIcon />} onClick={handleOpenModal}>
                      {t('items.delete')}
                    </Button>
                  }
                </div>
              )}
            </FormControl>
          </form>
        </Grid>
      </Grid>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="Confirm Modal"
      >
        <Paper elevation={3} className={classes.modalPaper}>
          <IconButton className={classes.closeModal} aria-label="close modal" component="span" onClick={() => handleCloseModal()}>
            <CloseIcon />
          </IconButton>
          <Typography>{t('items.delete-confirm-text', { 'itemName': itemState.name })}</Typography>
          <Button variant="contained" color="primary" onClick={() => handleCloseModal()}> {t('items.cancel')} </Button>
          <Button variant="contained" color="secondary" onClick={() => deleteUserOnClick()}> {t('items.delete')} </Button>
        </Paper>
      </Modal>
    </Fragment>
  )
}

export default ItemEdit
