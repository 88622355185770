import React, { ReactNode } from 'react';
import useStyles from './modalStyles';  

interface ModalProps {
  children: ReactNode;
}

const Modal: React.FC<ModalProps> = ({ children }) => {

  const classes = useStyles();
  return (
    <div className={classes.modal}>
      <div className={classes.modalContent}>
        {children}
      </div>
    </div>
  );
}

export default Modal;
